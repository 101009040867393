// Styles
import './styles/App.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams } from "react-router-dom";
import { getCity, getCanvas, getCities } from "./api";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [canvasImg, setCanvasImg] = useState(false);
  const [canvasData, setCanvasData] = useState(false);
  const [canvasId, setCanvasId] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(false);
  const [canvasHeight, setCanvasHeight] = useState(false);
  const [canvasStart, setCanvasStart] = useState(false);

  function changeBackground(e) {
    let city = e.target.getAttribute("data-city");
    e.target.style.backgroundImage = `url('/cities/` + city + `-2.png')`;
    e.target.classList.toggle("active");
  }  

  function changeBackgroundBack(e) {
    let city = e.target.getAttribute("data-city");
    e.target.style.backgroundImage = `url('/cities/` + city + `-1.png')`;
    e.target.classList.toggle("active");    
  } 

  useEffect(() => {
    console.log('hi');
    if (!loaded){  
      document.body.classList.add('body-canvas');
      window.scrollTo(0, 0);
      setLoaded(true)
    }
  },[loaded]);

  function linkCanvasPopup(e) {
    e.preventDefault();
    let canvasImage = e.currentTarget.getAttribute("data-canvas-image");
    let canvasId = e.currentTarget.getAttribute("data-canvas-id");
    let canvasWidth = e.currentTarget.getAttribute("data-canvas-width");
    let canvasHeight = e.currentTarget.getAttribute("data-canvas-height");
    let canvasStart = e.currentTarget.getAttribute("data-canvas-start");
    console.log(e.currentTarget.querySelector('img'), 'canvas')
    setModalActive(true);
    setCanvasImg(canvasImage);
    setCanvasData(canvasId);
    setCanvasWidth(canvasWidth);
    setCanvasHeight(canvasHeight);
    setCanvasStart(canvasStart);
    // e.currentTarget.className += " active";
  }

  function closeModal() {
    setModalActive(false);
  }

  function canvasLinkTiles() {
    let cDataArea = canvasWidth * canvasHeight
    let tiles = [];
    console.log(canvasStart, 'canvasStart')
    for (let i = canvasStart; i < cDataArea + parseInt(canvasStart); i++) {
      let tokenId = cDataArea - (cDataArea - i)
      let tile = {
        id: tokenId,
        link: 'https://opensea.io/assets/ethereum/0xa0e8a9941d1e1bc2ed25f138be6b0b51c059b298/' + tokenId
      }

      tiles.push(tile)
    }

    console.log(tiles, 'tiles');
    return (
      <>
      {tiles.map((sub) => (
      <div className={`canvas-link-tile canvas-link-tile--width-${canvasWidth}`}>
            <a rel="noreferrer" target="_blank" href={sub.link}>
              <img src={`/view-more-canvas-1.png`} />
            </a>
      </div>
      ))}
      </>
    )
  }

  function canvasNameSplit(name) {
    var splitName = name.split(' ');

    return (
      <>
      <span className="canvas-tile__name-span">{splitName[0]}</span>
      <span className="canvas-tile__number-span">{splitName[1]}</span>
      </>
    )
  }

  const { cityId } = useParams();
  const city = getCity(cityId);

  return (
    <section id="paint-city" className={`city-section ${cityId == 'nyc21' && 'city-section--nyc21'}`}>
      <div className={`canvas-overlay ${modalActive && 'active'}`} onClick={closeModal}></div>
      <div className={`canvas-modal ${modalActive && 'active'}`}>
        <div className="canvas-close" onClick={closeModal}>x</div>

        <div className="canvas-modal-inner">
          <div className="canvas-link-tiles">
            {canvasLinkTiles()}
          </div>
          <img alt="canvas-full" src={canvasImg}/>
        </div>
      </div>
      <div className="grid-container">
        <Link className="canvas-back-link" to={'/canvas'}>
          <img alt="cities-back" src={'/cities-back.png'}/>
        </Link>
        <Grid className="grid-x grid-padding-x align-center">
          <Cell small={12} medium={12} large={12}>
            <img alt="canvas-city-banner" className="canvas-city-banner" src={`/city-banner/${city.id}.png`} />
          </Cell>
        </Grid>       
        <Grid className="grid-x grid-padding-x align-center">
            {city.canvases.map((sub) => (
              <Cell small={6} medium={4} large={4} key={sub.id}>
                <div className="canvas-tile" data-canvas-start={sub.start} data-canvas-id={sub.id} data-canvas-width={sub.width} data-canvas-height={sub.height} data-canvas-image={sub.image} onClick={linkCanvasPopup}>
                  <div className="canvas-tile__link" to={sub.id}>
                    <img className={`canvas-tile__thumb canvas-tile__thumb--portrait-${sub.width > sub.height}`} alt="canvas-thumbnail" src={sub.image} />
                  </div>
                  <h3 className="canvas-tile__name">{canvasNameSplit(sub.name)}</h3>
                </div>
              </Cell>
            ))}
        </Grid>
        {cityId == 'nyc21' && (
          <div className="first-party-note"><img src={'/nyc21-note.png'}/></div>  
        )}           
      </div>
    </section>            
  );
}
export default App;