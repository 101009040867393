// Styles
import './styles/App.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded){  
      document.body.classList.add('body-canvas');
      window.scrollTo(0, 0);
      setLoaded(true)
    }
  },[loaded]);

  return (
    <HelmetProvider >
      <Helmet>
        <title>FewoWorld</title>
        <meta name="description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta name="theme-color" content="#e3c20d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FewoWorld" />
        <meta property="og:description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta property="og:image" content={'fewoworld-og-image.jpg'} />        
      </Helmet> 
      <div className="standard-page" style={{backgroundImage: `url('fewo-world-bg-light-cloud.webp')`}}>
        <section className="inline-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={6} large={6}>
                <Link to={'/'} className="logo-link-wrapper"><img className="App-logo" src={'fewoworld-logo-paper@2x.png'} /></Link>
              </Cell>
            </Grid>
          </div>          
        </section>
        <section className="standard-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={10} large={10}>
                <h2>Legal</h2>
                <p>Each Canvas NFT is a digital representation of a section of a real painted canvas (the “Artwork”). It does not represent any right, title or interest in the Artwork, any intellectual property rights therein or any other derivative works of the Artwork.  ArtArtArt Company LLC is and will remain the sole and exclusive owner of all right, title, and interest in and to the Artwork.  Each holder of a Canvas NFT that is released on October 13 agrees that a royalty will be paid to ArtArtArt Company LLC in connection with all sales of the Canvas NFTs by holders. The royalty will be equal to 10% of the sale price of the Canvas NFT received by the holder. The royalties received by ArtArtArt Company LLC will be used to support future paint parties.</p>
              </Cell>
            </Grid>                        
          </div>
        </section>            
       </div>
    </HelmetProvider>

  );
}
export default App;