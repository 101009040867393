import React, {useState, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Button, Grid, Cell } from 'react-foundation'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SectionWelcome = ({welcomeLoaded, parentCallback}) => {
  const [loaded, setLoaded] = useState(false)
  const [prevLoad, setPrevLoad] = useState(false)

  useEffect(() => {
    if (!loaded && prevLoad) {
      // console.log('gsap 3')

      gsap.timeline({
        scrollTrigger: {
          trigger: "#drop-info",
          pin: true,
          end: "+=200%",
          // scrub: 0.4,
          immediateRender: false
          // markers: true
        }
      })    
      .to(".drop-info__description", {
        opacity: 1,
        // y: -100,
        scale: 1.35,
        duration: 0.4
      }) 

      setLoaded(true)
      parentCallback(true) 
    }
  }, [loaded, prevLoad])

  useEffect(() => {
    // console.log(welcomeLoaded, 'welcomeLoaded')
    if (welcomeLoaded == true) {
      setPrevLoad(true)
    }
  }, [welcomeLoaded])

  return (
    <section id="drop-info" className="welcome-section">
      <Grid className="grid-x grid-padding-x align-center App-header__inner">
        <Cell small={12} medium={6}>
          <p className="intro-section__welcome drop-info__description description pin">FewoWorld is the first generative art project from the mind of FEWOCiOUS.<br/><br/>Unlike anything he has created before...</p>
        </Cell>
      </Grid>        
    </section> 
  )
}

export default SectionWelcome;