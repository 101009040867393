import React, {useState, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Button, Grid, Cell } from 'react-foundation'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SectionWelcome = ({headerLoaded, parentCallback}) => {
  const [loaded, setLoaded] = useState(false)
  const [prevLoad, setPrevLoad] = useState(false)

  useEffect(() => {
    if (!loaded && prevLoad) {
      // console.log('gsap 2')

      gsap.timeline({
        scrollTrigger: {
          trigger: "#about",
          pin: true,
          end: "+=200%",
          // scrub: 0.4,
          immediateRender: false
          // markers: true
        }
      })    
      .to(".welcome-description", {
        opacity: 1,
        // y: -100,
        scale: 1.35,
        duration: 0.4
      }) 

      setLoaded(true)
      parentCallback(true) 
    }
  }, [loaded, prevLoad])

  useEffect(() => {
    // console.log(headerLoaded, 'headerLoaded')
    if (headerLoaded == true) {
      setPrevLoad(true)
    }
  }, [headerLoaded])

  return (
    <section id="about" className="welcome-section">
      <Grid className="grid-x grid-padding-x align-center App-header__inner">
        <Cell small={12} medium={8}>
          <p className="intro-section__welcome welcome-description description pin">Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community</p>
        </Cell>
      </Grid>        
    </section>
  )
}

export default SectionWelcome;