import React, {useState, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Button, Grid, Cell } from 'react-foundation'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SectionPaintColored = ({paintIntroLoaded, parentCallback}) => {
  const [loaded, setLoaded] = useState(false)
  const [prevLoad, setPrevLoad] = useState(false)
  const [imagesRequested, setImagesRequested] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageLoadedArray, setImageLoadedArray] = useState([]);  

  useEffect(() => {
    if (paintIntroLoaded == true) {
      setImagesRequested(true)
    }
  }, [paintIntroLoaded])

  // useEffect(() => {
  //   if (prevLoad) {  
  //     setImagesRequested(true)  
  //   }
  // }, [prevLoad])

  useEffect(() => {
    if (imagesRequested) {
      let section = document.querySelector(".paint-section-one");

      if (section) {
        let canvas = section.querySelector("canvas");
        if (canvas) {        
          initCanvasColored(section, canvas) 
        }
      }      
    }
  }, [imagesRequested])

  // Check if all are loaded
  useEffect(() => {
    let allLoaded = allTrue(imageLoadedArray);

    if (allLoaded) {
      // console.log('all the images were loaded')
      // console.log(imageLoadedArray)
      setImagesLoaded(true) 
      parentCallback(true)       
    } 
  }, [imageLoadedArray])

  const allTrue = (obj) => {
    if (obj.length == 0) 
      return false

    for(var img in obj) {
        if (imageLoadedArray[img].loaded == true) {
          return true;
        }
    }

    return false;
  }

  function initCanvasColored(section, canvas) {
    let text = section.querySelector(".text");
    let context = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;  

    let frameCount = 100;
    const currentFrame = index => (
      `/paint-colored-webp/Colored_${(index + 1).toString().padStart(4, '0')}.webp`
    );

    let images = []
    let loadedImages = []

    let paintdrops = {
      frame: 0
    };
    
    for (let i = 0; i < frameCount; i++) {
      let img = new Image();
      img.src = currentFrame(i);

      let imgAttr = {
        src: currentFrame(i),
        loaded: false
      }

      img.onload = () => {     
        loadedImages[i]['loaded'] = true
        setImageLoadedArray(loadedImages)
      }      

      images.push(img);
      loadedImages.push(imgAttr)

    }

    const handleResize = e => {
      canvas.height = window.innerHeight;
      canvas.width = window.innerWidth;
      render()
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // console.log('gsap 5');

    gsap.timeline({
      onUpdate: render,
      scrollTrigger: {
        trigger: section,
        pin: true,
        // scrub: 0.4,
        end: "+=350%",
        immediateRender: false,
        toggleActions: "resume pause resume pause"
        // markers: true
      }
    })
    .to(text, {
      opacity: 1,
      x: -100,
      duration: 0.4
    })  
    .to(paintdrops, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      duration: 3,
      repeat: -1
    }, 0);
    
    images[0].onload = () =>{
      render();
      loadedImages[0]['loaded'] = true
      setImageLoadedArray(loadedImages)
    }

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height);

      let dim = (800 < window.innerHeight) ? 800 : window.innerHeight;
      let left = (window.innerWidth / 2) - (dim / 4);
      let top = (window.innerHeight - dim) / 2;

      if (window.innerWidth < 1024) {
        dim = window.innerWidth * .6;
        left = (window.innerWidth / 2) - (dim / 4);
        top = (window.innerHeight - dim) / 2;
      }

      if (window.innerWidth < 640) {
        dim = window.innerWidth;
        left = 0;
        top = 0;
      }    

      context.drawImage(images[paintdrops.frame], left, top, dim, dim); 
    } 
     
  }

  return (
    <section className="paint-section-one">
      <canvas className="colored"></canvas>
      <div className="grid-container">
        <Grid className="grid-x grid-padding-x">
          <Cell small={12} medium={6}>
            <div className="text">
              <p>PAINT is the building block to all of life in FewoWorld.</p>
            </div>              
          </Cell>
        </Grid>
      </div>
    </section>
  )
}

export default SectionPaintColored;