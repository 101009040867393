import React, {useState, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SectionHeader = ({canvasLoaded, parentCallback}) => {
  const [navState, setNavState] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [gsapEnabled, setGsapEnabled] = useState(false);
  const [everythingLoaded, setEverythingLoaded] = useState(false)
  
  const executeScroll = (section, showMenu) => {
    if (typeof showMenu == "undefined" && showMenu !== false) {
      handleNavState();
    }

    let offsetY = 0;
    if (section === 'about') {
      offsetY = -300;
    }
    if (section === 'canvas') {
      offsetY = -300;
    }
    gsap.to(window, {duration: 1, scrollTo:{y:`#${section}`, offsetY:offsetY}});
  }

  const handleNavState = () => {
    if (!navState) {
      setNavState(true)
    } else {
      setNavState(false)      
    }
  }

  useEffect(() => {
    if (canvasLoaded) {
      setEverythingLoaded(true)

      if(window.location.hash) {
        let hash = window.location.hash
        hash = hash.replace('#', '');
        executeScroll(hash)
      }      
    }
  }, [canvasLoaded])


  useEffect(() => {

  })

  useEffect(() => {

    // setTimeout(()=> {

      if (imageArray.length > 0) {
        setImagesLoaded(true)    
        parentCallback(true)  

        // console.log('gsap 1')

        let introPin = gsap.timeline({
          scrollTrigger: {
            trigger: '.main-intro-section',
            pin: false,
            end: "+=200%",
            // scrub: 0.4,
            immediateRender: false,
            refreshPriority: -1,
            // markers: true
          }
        })       

        let introGlide = gsap.timeline({
          scrollTrigger: {
            scrub: true,
            // markers: true
          }
        })        
        introGlide.to(".App-header", {
          y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed * 0.25,
          ease: "none"
        })
      }
    // }, 10000)

  }, [imageArray])

  useEffect(() => {
    if (!imagesLoaded) {
 

      let images = [
        {
          url: '/fewos-nav-about.png',
          loaded: false
        },
        {
          url: '/fewos-nav-paint.png',
          loaded: false
        },        
        {
          url: '/fewos-nav-canvas.png',
          loaded: false
        },
        {
          url: '/fewos-nav-fewos.png',
          loaded: false
        },
        {
          url: '/fewos-nav-no-roadmap.png',
          loaded: false
        },
        {
          url: '/twitter.png',
          loaded: false
        },
        {
          url: '/instagram.png',
          loaded: false
        },
        {
          url: '/discord.png',
          loaded: false
        },                        
        {
          url: '/youtube.png',
          loaded: false
        }, 
        {
          url: '/connect-wallet-button.png',
          loaded: false
        },
        {
          url: '/fewos-click.png',
          loaded: false
        },
        {
          url: '/fewoworld-logo-paper@2x.png',
          loaded: false
        },                                              
        {
          url: '/mobile-nav-bg.jpg',
          loaded: false
        },
        {
          url: '/menu-button-texture-yellow.jpg',
          loaded: false
        },
        {
          url: '/buy-button-pink.png',
          loaded: false
        },
        {
          url: '/fewos-nav-lore.png',
          loaded: false
        },
        {
          url: '/fewos-mint-nav.png',
          loaded: false
        }, 
        {
          url: '/fewos-nav-faq-1.png',
          loaded: false
        }, 
        {
          url: '/fewo-fashion.png',
          loaded: false
        },                                           
      ];

      asyncCall(images)
    }
  }, [imagesLoaded])


  function loadImagePromise(images) {
    return new Promise(resolve => {
      setTimeout(() => {
        for (let i = 0; i < images.length; i++) {
          let img = new Image();
          img.src = images[i]['url'];
          img.onload = () => {
            images[i]['loaded'] = true
          }
        }
        resolve(images)
      }, 1000)
    });
  }

  async function asyncCall(imgs) {
    // console.log('calling');
    // const result = await loadImgs(imgs);
    const result = await loadImagePromise(imgs);
    // console.log(result, 'THE RESULT')
    setImageArray(result);
  }
  

 

  let navMobMenuClass = navState ? 'App-nav App-nav--mobile App-nav--mobile--active' : 'App-nav App-nav--mobile'
  let navMobMenuBtnClass = navState ? 'App-menu App-menu--active' : 'App-menu'  
  let logo = typeof imageArray[11] != "undefined" ? imageArray[11].url : '';
  let scrollIcon = typeof imageArray[10] != "undefined" ? imageArray[10].url : '';
  let mobileNavBg = typeof imageArray[12] != "undefined" ? imageArray[12].url : '';
  let loadingScreen = imagesLoaded && everythingLoaded ? 'disable' : 'show';
  let menuZIndex = navState ? 'higher' : ''
  return (
    <>
      <div className={`loading-overlay ${loadingScreen}`} style={{backgroundImage: `url('loading-bg.jpg')`}}>
        <span className="loading">LOADING...</span>
      </div>
      <section className={`main-intro-section ${menuZIndex}`}>
        <header className="App-header" data-speed="0.05">
        {imagesLoaded && everythingLoaded && (
          <>
            <nav className="App-nav App-nav--desktop">
              <ul className="App-nav__list">
                <li className="App-nav__list-item App-nav__list-item--about" style={{backgroundImage: `url(${imageArray[0].url})`}}>
                  <button onClick={() => executeScroll('about')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--paint" style={{backgroundImage: `url(${imageArray[1].url})`}}>
                  <button onClick={() => executeScroll('paint')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--canvas" style={{backgroundImage: `url(${imageArray[2].url})`}}>
                  <button onClick={() => executeScroll('canvas')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--fewos" style={{backgroundImage: `url(${imageArray[3].url})`}}>
                  <button onClick={() => executeScroll('fewos')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--noroadmap" style={{backgroundImage: `url(${imageArray[4].url})`}}>
                  <button onClick={() => executeScroll('no-roadmap')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--lore" style={{backgroundImage: `url(${imageArray[15].url})`}}>
                  <button onClick={() => executeScroll('lore')}></button>
                </li>
                <li className="App-nav__list-item App-nav__list-item--lore" style={{backgroundImage: `url(${imageArray[17].url})`}}>
                  <Link to={'/faq'}></Link>
                </li>   
                <li className="App-nav__list-item App-nav__list-item--lore" style={{backgroundImage: `url(${imageArray[18].url})`}}>
                  <a href="https://fashion.fewoworld.io" target="_blank" rel="no-referrer"></a>
                </li>                              
                {/*<li className="App-nav__list-item App-nav__list-item--fewocious" style={{backgroundImage: `url(${imageArray[16].url})`}}>
                  <a href="https://fewos.fewoworld.io" rel="noreferrer" target="_blank"></a>
                </li>*/}                                                                                      
              </ul>
            </nav>

            <img className="App-logo pin" alt="FewoWorld" src={logo} />

            <div className="App-login-wrapper">
              <span>Log In to View Your Fewos Dashboard</span>
              <a className="button App-create-button" href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">LOG IN</a>                  
            </div>
            {/*<a href="/fewos"><img className="App-scroll-icon" src={scrollIcon} alt="scroll icon"/></a>*/}
            {/*<a href="http://fewos.fewoworld.io/" target="_blank" className="fewos-mint-link-home App-scroll-icon">
              <img className="fewos-launching-soon d" src={'/fewos-mint-button-link.png'} alt="Fewos Mint" />
            </a>*/}            
            <div className="header-social-links">
              <div className="header-social-link header-social-link--twitter" style={{backgroundImage: `url(${imageArray[5].url})`}}>
                <a href="https://twitter.com/fewoworld" target="_blank" rel="noreferrer"></a>
              </div>
              <div className="header-social-link header-social-link--instagram" style={{backgroundImage: `url(${imageArray[6].url})`}}>
                <a href="https://instagram.com/fewocious" target="_blank" rel="noreferrer"></a>
              </div>
              <div className="header-social-link header-social-link--discord" style={{backgroundImage: `url(${imageArray[7].url})`}}>
                <a href="https://discord.com/invite/wfHtcz5Pm5" target="_blank" rel="noreferrer"></a>
              </div>
              <div className="header-social-link header-social-link--youtube" style={{backgroundImage: `url(${imageArray[8].url})`}}>
                <a href="https://www.youtube.com/channel/UCmJPFfiUNWJWXh4cZnQ-clA" rel="noreferrer" target="_blank"></a>
              </div>
            </div>          

            <a href="https://opensea.io/collection/fewoworld-paint" target="_blank" rel="noreferrer" className="nav-buy-btn" style={{backgroundImage: `url(${imageArray[14].url})`}}>
              View Paint
            </a>

            {/*}
            <button className="connect-wallet">
              <div className="connect-wallet__btn" style={{backgroundImage: `url(${imageArray[9].url})`}}></div>
              <span className="connect-wallet__coming-soon">Coming Soon</span>
            </button>
            {*/}
          </>           
        )}
             
        </header>

        {imagesLoaded && everythingLoaded && (
          <div className={navMobMenuBtnClass} onClick={handleNavState} style={{backgroundImage: `url(${imageArray[13].url})`}}>
            <img alt="menu" className="menu-icon" src={'menu.svg'} />
            <img alt="close menu" className="close-icon" src={'close.svg'} />
          </div>
        )} 
        {imagesLoaded && everythingLoaded && (
          <nav className={navMobMenuClass} style={{backgroundImage: `url(${mobileNavBg})`}}>
            <img className="App-logo-mobile" alt="FewoWorld" src={logo} />
            <ul className="App-nav__list">
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[0].url})`}}>
                <button onClick={() => executeScroll('about')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[1].url})`}}>
                <button onClick={() => executeScroll('paint')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[2].url})`}}>
                <button onClick={() => executeScroll('canvas')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[3].url})`}}>
                <button onClick={() => executeScroll('fewos')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[4].url})`}}>
                <button onClick={() => executeScroll('no-roadmap')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[15].url})`}}>
                <button onClick={() => executeScroll('lore')}></button>
              </li>
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[17].url})`}}>
                <Link to={'/faq'}></Link>
              </li>                 
              <li className="App-nav__list-item" style={{backgroundImage: `url(${imageArray[18].url})`}}>
                <a href="https://fashion.fewoworld.io" target="_blank" rel="noreferrer"></a>
              </li>                                                                                       
            </ul>
          </nav>
        )} 
      </section>
    </>
  )

}

export default SectionHeader;