// Styles
import './styles/App.scss'

import React, { useState, useEffect } from 'react'
import { Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded){  
      document.body.classList.add('body-faq');
      if (window.location.href.includes('#pricing')) {
        document.getElementById('pricing').scrollIntoView({
          behavior: 'smooth'
        })      
      } else {
        window.scrollTo(0, 0);
      }

      setLoaded(true)
    }
  },[loaded]);

  return (
    <HelmetProvider >
      <Helmet>
        <title>FewoWorld</title>
        <meta name="description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta name="theme-color" content="#e3c20d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FewoWorld" />
        <meta property="og:description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta property="og:image" content={'fewoworld-og-image.jpg'} />        
      </Helmet> 
      <div className="standard-page">
        <section className="inline-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={6} large={6}>
                <Link to={'/'} className="logo-link-wrapper"><img className="App-logo" src={'fewoworld-logo-paper@2x.png'} alt="logo" /></Link>
              </Cell>
            </Grid>
          </div>          
        </section>
        <section className="standard-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={10} large={10}>
                <h1>FAQs</h1>

                <h2 className="faq-section-heading">Digital Fashion</h2>                
                
                <div className="faq-qa">
                  <h3>How can I get Fewo Digital Fashion?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewo Digital Fashion items are ERC 1155 tokens and are available via burning Fewo Flowers. When the burn / redeem page opens on February 1st, you can connect your wallet (make sure to have your Fewo Flowers in that wallet) and burn flowers to redeem digital wearables.</p>
                  </div>                  
                </div>

                <div className="faq-qa">
                  <h3>How long is the redemption window open?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Season 1 of Fewo Digital Fashion will be available from February 1st - February 29th. You can return to the website multiple times and do multiple burn transactions during that period.</p>
                  </div>                  
                </div>                

                <div className="faq-qa">
                  <h3>Is there a limit to how many Fewo Flowers I can burn at a time?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>There is a limit of 50 tokens per transaction.</p>
                  </div>                  
                </div>
  
                <div className="faq-qa">
                  <h3>How can my Fewo wear the digital wearables?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>You can transfer your new digital wearable tokens into your Fewo’s backpack via the Dressing Room and equip / unequip their new wearables. Make sure to always refresh your Fewo’s metadata after making changes to view those changes on Opensea.</p>
                  </div>                  
                </div>

                <div className="faq-qa">
                  <h3>How do the tiers work?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewo digital wearables are separated into tiers that correspond with the tiers of Fewo Flowers. You can exchange Common Flowers for Common digital wearables, Epic Flowers for Epic digital wearables, etc.</p>
                  </div>                  
                </div>                

                <h2 className="faq-section-heading">Fewos</h2>                
                {/*<div className="faq-qa">
                  <h3>How will holders of tokens not on Ethereum be able to access the mint?</h3>
                  <div className="faq-qa-answer-wrapper">
                    Fewos will have multiple sale windows which can be found at <a href="https://fewoworld.io/fewos" rel="noreferrer">fewoworld.io/fewos</a>. Paint Holders will receive their first free Fewo via airdrop. The rest of the Fewos will be claimed via a mint.                     
                  </div>
                </div>*/}
                <div className="faq-qa">
                  <h3>Will Fewos be fully generative or will you equip your fewo with traits e.g. by having more paint in Paint drop?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewos are a random generative 18,000 supply. Base traits will not be changeable. The only thing that will change is the additional wearables and other holdable items you can equip to the Fewo.</p>
                  </div>                  
                </div>  
                <div className="faq-qa">
                  <h3>How do Custom Fewos work?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Top Paint holders should connect their wallet to the Custom Fewo Form on the fewoworld.io website to answer the questions and provide an inspiration image. Custom Fewos will be air dropped to Top Paint holders on October 2nd at 3PM ET. Please allow some buffer time for all airdropped Fewos to land in the wallets.</p>
                    <p>If you don’t have your Top Paint token in a non-custodial wallet please connect to the FewoWorld team via discord or email at <a href="mailto:art@fewocious.com">art@fewocious.com</a>. We can help you gain access to the Custom Fewos form.</p>
                    <p>Custom Fewo forms filled out before September 10th will receive their Custom Fewos during Mint Week. The form will stay open after that, but you will receive a placeholder unrevealed custom Fewo until the art is ready after Mint Week. </p>
                  </div>                                
                </div>
                <div className="faq-qa">
                  <h3>When will my Custom Fewo reveal if I have a Mystery Box Custom Fewo?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Each Custom Fewo is designed individually. The remaining unrevealed Custom Fewos will be delivered in two batches on the following timelines to ensure all Custom Fewo holders will be able to enjoy the Dressing Room shortly after it opens.</p>
                    <p>October 31st - any Top Paint Custom Fewos who submitted their form before mint day.</p>
                    <p>November 30th - Top Paint Custom Fewos who submitted their form after mint day and Mystery Box Custom Fewos.</p>
                  </div>                                
                </div>                                
                  {/*<div className="faq-qa">
                    <h3>How will the mint work?</h3>
                    <div className="faq-qa-answer-wrapper">                  
                      <p>There are 5 days of the mint - outlined below:</p>
                      <p><strong>MONDAY SEPTEMBER 25TH - Snapshot</strong></p>
                      <p>Snapshots will be taken of all wallets holding Paint Drops and FEWOCiOUS Art Pieces.</p>
                      <p><strong>TUESDAY SEPTEMBER 26TH - Paint Holder Airdrop</strong></p>
                      <p>ALL Paint Holders will receive their first Free Fewo via Airdrop to the wallet captured during the Snapshot. This Airdrop will result in 7,305 Fewos being minted.</p>                    
                      <p><strong>WEDNESDAY SEPTEMBER 27TH - Paint Holder Presale</strong></p>
                      <p>Paint Drop Holders can connect their wallets and mint their additional Fewos if they are eligible via the Flower Exchange. The total amount of eligible Fewos displayed reflects ALL of the Paint Drops in your connected wallet. As you toggle your mint number up and down, your remaining Flower count will be re-calculated.</p>                                        
                      <p><strong>THURSDAY SEPTEMBER 28TH - FEWOCiOUS Art Holder Presale</strong></p>
                      <p>Art Holders can connect their wallet and see the total number of eligible mints based on all the FEWOCiOUS Art tokens in their wallet. You can see a list of all FEWOCiOUS Art below. Art Holders will mint at a discounted price.</p>
                      <p><strong>FRIDAY SEPTEMBER 29TH - Public Sale</strong></p>
                      <p>Buyers duriing the Public Sale can mint as many Fewos as they want during the Public Sale.</p>                    
                    </div>
                  </div>*/}                  
                {/*<div className="faq-qa">
                  <h3>How will the mint work?</h3>
                  <div className="faq-qa-answer-wrapper">
                    <p>There are 5 days of the mint - outlined below:</p>
                    <ul>
                      <li>
                        <p>MONDAY SEPTEMBER 25TH 1PM ET- Snapshot</p>
                        <ul>
                          <li>
                            Snapshots will be taken of all wallets holding Paint Drops and FEWOCiOUS Art Pieces. Once the Snapshot is taken, the first free Fewo per Paint Drop will be sent to the wallet that held the Paint Drop at the time of the snapshot. The Flowers associated with that Paint Drop will also be sent to the wallet captured at the time of snapshot when the Flowers are airdropped. Total eligible Fewos for the Paint Holder Flower Exchange & Mint and the FEWOCiOUS Art Holder Mint will be calculated on a per wallet basis. 
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>TUESDAY SEPTEMBER 26TH 1PM - 3PM ET - Paint Holder Airdrop</p>
                        <ul>
                          <li>
                            ALL Paint Holders will receive their first Free Fewo via Airdrop to the wallet captured during the Snapshot. This Airdrop will result in 7,305 Fewos being minted.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>WEDNESDAY SEPTEMBER 27TH 12PM - 10PM ET - Paint Holder Flower Exchange & Mint</p>
                        <ul>
                          <li>
                            Paint Holders with 10 Paint or more in their Drops will be able to mint their additional free Fewos in exchange for Flowers! Your total eligible amount will be calculated on a per wallet basis. As you toggle your mint number up and down, your remaining Flower count will be re-calculated. 
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>THURSDAY SEPTEMBER 28TH 1PM ET - FEWOCiOUS Art Holder Mint</p>
                        <ul>
                          <li>
                            Art Holders can connect their wallet and see the total number of eligible mints based on all the FEWOCiOUS Art tokens in their wallet. You can see a list of all FEWOCiOUS Art below. 
                          </li>
                          <li>
                            Art Holders will mint at a 0.125 ETH Maximum Price. Please see the Pricing FAQ for more details. 
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>FRIDAY SEPTEMBER 29TH - 10AM - 4PM ET Public Mint</p>
                        <ul>
                          <li>
                            10AM EST - Nifty Gateway Mint (on Nifty Gateway)
                          </li>
                          <li>
                            12PM EST - Allowlist Mint (on FewoWorld Website)
                            <ul>
                              <li>nft now (Now Pass)</li>
                              <li>Rug Radio</li>
                              <li>VeeFriends (Mint Mink Tokens)</li>
                            </ul>
                          </li>
                          <li>
                            2PM - 4PM EST - Allowlist Mint (on FewoWorld Website)
                            <ul>
                              <li>Buyers during the Public Mint can mint as many Fewos as they want at a 0.4 ETH Maximum Price. Please see the Pricing FAQ for more details.</li>
                            </ul>                            
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>MONDAY OCTOBER 2ND - 1PM - 2PM ET Reveal</p>
                      </li>
                      <li>
                        <p>THURSDAY OCTOBER 5TH - 1PM - 3PM ET Flower Airdrop</p>
                        <ul>
                          <li>Your Flowers will be Airdropped to the wallet you used for Mint</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="faq-qa">                
                  <h3>When will Fewos reveal?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewos will reveal on October 2 between 1-2 PM ET.</p>
                  </div>                  
                </div>*/}                  
                {/*<div className="faq-qa">                
                  <h3>How will holders of tokens not on Ethereum be able to access the mint?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Billboard NFT Holders and Decentraland Helmet Holders will be contacted to provide their ETH addresses to gain whitelist access to Fewos.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>When will mint prices be announced?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Mint pricing will be announced at least 10 business days prior to mint.</p>
                  </div>
                </div>  
                <div className="faq-qa">
                  <h3>Will Fewos be fully generative or will you equip your fewo with traits e.g. by having more paint in Paint drop?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewos are a random generative 18,000 supply. Base traits will not be changeable.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Is this mint a claim or an airdrop?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewos will have multiple sale windows. Paint Holders will receive their first free Fewo via airdrop. The rest of the Fewos will be distributed via mint.</p>
                  </div>
                </div>*/}
                <div className="faq-qa">
                  <h3>Do wearables show up as changes to your actual NFT?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>You can equip your Fewo with wearables that are in your backpack in real time. The Fewo itself will be a fixed generative NFT that you mint, then wearables in your backpack will change the appearance based on how you equip.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Do equipped items & wearables affect the rarity of your Fewo or its on-chain traits?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Yes!</p>
                  </div>
                </div> 
                <div className="faq-qa">
                  <h3>Are Fewos random or tiered?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Fewos are completely random. Flowers and wearables that can be equipped to your Fewos will include tiered items.</p>
                  </div>
                </div>
                {/*<div className="faq-qa">
                  <h3>When I buy a Fewo on secondary, does it also come with it’s backpack wallet?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Yes! You’ll be able to buy the Fewo with everything it’s holding in it’s backpack wallet. Be sure to remove any NFTs you don’t intend to sell with your Fewo if you’re considering listing on secondary markets.</p>
                  </div>
                </div>*/}
                <div className="faq-qa">
                  <h3>How do I check the items in my Fewo’s backpack?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>You can view your Fewo’s backpack in the Dressing Room on the FewoWorld website. Each Fewo’s backpack will also be linked in the description on Opensea.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Is there a Fewos Vault or any Fewos being held back from the supply?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Up to 1872 total Fewos will be vaulted & held back. 1000 to account for up to 5% of total supply for the Fewos team, and 872 which accounts for the 872 Paint Drops currently held in the vault for future community use. At minimum, 872 will be vaulted, with the additional 5% being informed by supply going into the Public Mint.</p>
                  </div>
                </div>
                {/*<div className="faq-qa">
                  <h3>Should I buy a Fewo on secondary with other non-FewoWorld tokens in it’s backpack wallet?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>We advise against purchasing Fewos on secondary with non-FewoWorld tokens in their backpack wallets. Most marketplaces don’t have protections against removal of items from 6551 wallets after accepting offers. Always double check backpacks before placing offers. We encourage purchasing wearables and holdables as separate tokens on secondary and then transferring into your Fewo’s backpack wallet until marketplaces have the proper protections in place.</p>
                  </div>
                </div>*/}                
                {/*<div id="pricing" className="faq-qa">
                  <h3>How does pricing work?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Pricing Mechanics: There will be a maximum price of .125 ETH for the FEWOCiOUS Art Holder Mint and .4 ETH for the Public Mint. Final pricing will be determined mathematically by the supply of Fewos left to mint after the Paint Holder Airdrop and Paint Holder Flower Exchange & Mint. At maximum, there will be approximately 14k Fewos minted after the Paint Holder Flower Exchange & Mint (of the total 20k supply). For every 500 Fewos short of the 14k possible, the FEWOCiOUS Art Holder Mint & Public Mint prices will reduce by 5% for the remaining supply.</p>
                    <p>Example: If 13.5k Fewos have been minted / airdropped total after the Paint Holder Airdrop and Paint Holder Flower Exchange & Mint, the prices would reduce by 5%, if 13k, by 10%, if 12.5k, then by 15% and so on. Final prices and leftover supply will be published shortly after the end of the Paint Holder Flower Exchange & Mint.</p>
                  </div>
                </div>*/} 
                {/* <div className="faq-qa">
                  <h3>Is there a Fewos Vault?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>5% of the total supply of Fewos will be held back. 872 total Fewos will be in the FewoWorld Vault. These 872 Fewos are the adjoining Fewos to the Paint Drops still held in the Vault and will be used for potential future giveaways. 128 total Fewos will be distributed to internal team members of FewoWorld. </p>
                  </div>
                </div>*/}                
                {/*<div className="faq-qa">
                  <h3>What counts as FEWOCiOUS Art for the Art Holders Presale?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <ul>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x5a103ceeee005becc42775764dcf5963fb8c4bdc/5?filters%5BonSale%5D=true&sortBy=lowest">i like being alive</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/collection/fewoworld-bonus-paint-trophies">Diamond Paint Trophy</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/collection/fewoworld-bonus-paint-trophies">Gold Paint Trophy</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/ethereum/0x0972290a80333d19c6703073c3e57134a4ca0127/3">FewoWorld Trophy</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://market.decentraland.org/contracts/0xf1ec64da82847bc0511c23d16794bc7e1683731f/items/1">RTFKT Fewo Epic</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x5a103ceeee005becc42775764dcf5963fb8c4bdc/4?filters%5BonSale%5D=true&sortBy=lowest">how to forget a sweetheart</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x5a103ceeee005becc42775764dcf5963fb8c4bdc/1?filters%5BonSale%5D=true&sortBy=lowest">sloppi striped sue</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x5a103ceeee005becc42775764dcf5963fb8c4bdc/2?filters%5BonSale%5D=true&sortBy=lowest">will being on the billboards fix me</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x5a103ceeee005becc42775764dcf5963fb8c4bdc/3?filters%5BonSale%5D=true&sortBy=lowest">i miss your voice call back soon okay</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0xa2d544196fdbc9da15a701c38bb60513c1802ef4/2?filters%5BonSale%5D=true&sortBy=lowest">The Day I Decided to Fly</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0xa2d544196fdbc9da15a701c38bb60513c1802ef4/1?filters%5BonSale%5D=true&sortBy=lowest">Moving On</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x22a7ac591ec9414e0547319d2fb9831b4aed6338/2">Afraid to Be Myself</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x22a7ac591ec9414e0547319d2fb9831b4aed6338/1?filters%5BonSale%5D=true&sortBy=lowest">Over-Analyzing Again</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x22a7ac591ec9414e0547319d2fb9831b4aed6338/1?filters%5BonSale%5D=true&sortBy=lowest">NYC Skyline</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x3b7296d63f799c1566b0c09df61cb7fdd6ac95cb/4?filters%5BonSale%5D=true&sortBy=lowest">Shattered Glass</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x3b7296d63f799c1566b0c09df61cb7fdd6ac95cb/3?filters%5BonSale%5D=true&sortBy=lowest">i don’t know how i feel right now</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x3b7296d63f799c1566b0c09df61cb7fdd6ac95cb/2?filters%5BonSale%5D=true&sortBy=lowest">Reaching for a Hand to Hold</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x3b7296d63f799c1566b0c09df61cb7fdd6ac95cb/1?filters%5BonSale%5D=true&sortBy=lowest">Moment i Fell in Love</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/1">Fewocious</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0xc633787348f652611c3166209d1fc4cd82b30ada/1">FEWO CHARM</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0xc633787348f652611c3166209d1fc4cd82b30ada/2?filters%5BonSale%5D=true&sortBy=lowest">FEWO DRIP</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x2250d7c238392f4b575bb26c672afe45f0adcb75/3?filters%5BonSale%5D=true&sortBy=lowest">FEWO SHOE RARE</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x2250d7c238392f4b575bb26c672afe45f0adcb75/2?filters%5BonSale%5D=true&sortBy=lowest">FEWO SHOE EPIC</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x2250d7c238392f4b575bb26c672afe45f0adcb75/1">FEWO SHOE LEGENDARY</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/4?filters%5BonSale%5D=true&sortBy=lowest">Victor and Victoria by FEWOCIOUS</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/3?filters%5BonSale%5D=true&sortBy=lowest">our home was never built to last by FEWOCIOUS</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x1c233a2676bd7ec4f83b78962712fb66350f7d26/2">The EverLasting Beautiful by FEWOCIOUS</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x1c233a2676bd7ec4f83b78962712fb66350f7d26/5?filters%5BonSale%5D=true&sortBy=lowest">Parrodius Jonathewo</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x63984ef7a91ea69cdad6c7071890ee4d2fccab4b/2?filters%5BonSale%5D=true&sortBy=lowest">CryptoCaster</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x63984ef7a91ea69cdad6c7071890ee4d2fccab4b/1?filters%5BonSale%5D=true&sortBy=lowest">City Hand</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x80a50b716ddc43f525c479925d6aa048604eab14/1">Crowded City</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x80a50b716ddc43f525c479925d6aa048604eab14/2">A Peak In My Head</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/2?filters%5BonSale%5D=true&sortBy=lowest">I’m sure it’s down here somewhere by Odious</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/8?filters%5BonSale%5D=true&sortBy=lowest">The Big (Rotten) Apple by Jonathan Wolfe</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/5?filters%5BonSale%5D=true&sortBy=lowest">Homage by parrot_ism</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/7?filters%5BonSale%5D=true&sortBy=lowest">Dream House by Jonathan Wolfe</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/1?filters%5BonSale%5D=true&sortBy=lowest">i’m protecting you from the road ahead by Odious</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x068f74749c24a42058563035f8c786362fc96494/6?filters%5BonSale%5D=true&sortBy=lowest">Arrival by parrot_ism</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x1c233a2676bd7ec4f83b78962712fb66350f7d26/1">Living in My Head by Jonathan Wolfe</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x1c233a2676bd7ec4f83b78962712fb66350f7d26/3">Where do I Belong? By parrot_ism</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://niftygateway.com/marketplace/collection/0x1c233a2676bd7ec4f83b78962712fb66350f7d26/4">never lose your inner child by Odious</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/86">Nice to meet you, I’m Mr. MiSUNDERSTOOD</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/85">Eighteen</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/84">It was always art</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/81">Life’s question</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/80">Mister MisUnderstood</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/83">It was always you</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/82">dull</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/79">Oh, to be missed</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/78">Nurturing a flower</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/77">woebegone</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/76">To love again</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/71">Call me sometime</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/75">Blueprint to a broken heart</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/74">Instant Better</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/73">Always and forever, im sorry</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/72">Who knew i’d be so lonely</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/70">Welcome, to FEWO-WORLD</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/69">I dont wanna be alone</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/67">When the sun gave me a kiss</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/68">Victor Valentine, he’s a lovestruck</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/63">Today i do not feel good</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/64">Trying to be happy</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/66">What’s it like to rest in peace</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/61">Sorry to be such a burden</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/59">ill-intended</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/65">Tulip’s Love Letter</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/62">Sweet tooth</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/60">Running away from bad thoughts</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/58">I wish i could sleep</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/56">I don’t like being afraid of myself</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/57">I walk among the flowers</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/55">I am doing so WELL</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/53">Drowning in my Tears</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/54">Familia 2020</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/50">When a Child Feels lost v1</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/52">Nervous doodle</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/51">I feel like im always falling behind</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/49">we were once inseparable</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/48">Us kids never forget</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/47">Stuff we’ve never said out loud before</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/46">Story about a scared kid named Victor</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/45">Sink or swim?</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/44">Sat and cried on the bathroom floor</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/43">Reaching for a hand to hold v. 1</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/42">More hallucinations</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/41">I will always fly</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/40">I miss the good ol’ days</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/39">i drew this when i was crying at 3 am</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/38">I am UNSTOPPABLE</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/37">more bad dreams & suffocating thoughts</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/36">when the bad thought start to creep in</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/35">Childhood trauma & neglect</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/34">I love you, Elle, because you are a reflection of me</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/33">who is Victor</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/32">We never read the contracts we sign</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/30">pinky promise</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/31">The devil was once mine</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/27">Family portrait</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/26">conversations overheard in the women’s restroom</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/29">No Longer My SuperStar</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/25">V & B are scared</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/28">I Hope you never experience grimm’s fairy tales</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/24">stop crying, or I’ll give you something to cry about</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/23">Childhood</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/22">They call me Drama Queen</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/21">i hide the part of my life that isn’t glamorous</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/20">5th Grade</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/19">Disruption</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/18">No one saw me</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/17">Internal pain</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/16">Read inbetween the lines</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/15">Captain of the Lonely Hearts Club</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/14">sloppi stripped sue’s sister, preppy patty polka</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/13">Scared Sunshine</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/12">Stop staring at me</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/11">iT HURTS TO HiDE</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/10">Miserable Self portrait</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/9">Victoria L. confusing thing</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/8">LoverBoy, always a fool</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/7">Existing</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/6">Working on it</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/5">Year 5, Age 18 — i Taught Myself How To Fly</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/4">Year 4, Age 17 — His Name is Victor</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/3">Year 3, Age 16 — when a child feels lost</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/2">Year 2, Age 15 — My Mama’s Dream</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xf126eb8284110a37c18e726a7e3b9fc21e68e897/1">Year 1, Age 14 — It Hurts To Hide</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xd07dc4262bcdbf85190c01c996b4c06a461d2430/180443">The Wanderers</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xd07dc4262bcdbf85190c01c996b4c06a461d2430/180410">Look Out In The World</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xe4605d46fd0b3f8329d936a8b258d69276cba264/122">Giving My Heart Away</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/19262">SuperRare World</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/18241">The Sailor</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/16606">The Modern Prometheus</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/15316">BitterSweet</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13927">It’s Easier to Get Lost When You’re Lonely</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13907">Hurt Feelings</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13670">The Innovator’s Dinner</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/13253">i flew too close to the sun</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/12195">i Dont Know Who i Am</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/11988">Overthinking Again</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/11756">Kiss Me Before You Leave</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/11294">So MaNY HEADaCHes</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/11154">i feel so lost…</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/10812">Rage & Love</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/10734">i Always Think of You</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/10425">She Makes Me Feel Alive</a></li>
                      <li><a target="_blank" rel="noreferrer" href="https://opensea.io/collection/fewocious-complexcon">FEWOCiOUS x ComplexCon</a></li>

                    </ul>
                  </div>
                </div>*/}

                <h2 className="faq-section-heading">Paint</h2>                
                <div className="faq-qa">
                  <h3>Does your Paint Drop burn when you mint your Fewo?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Paint Drops don’t burn upon mint. Once the dressing room launches, Paint Drops can be equipped to your Fewo and held as an in-hand accessory.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>My Paint Drop is on Nifty - what should I do?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>To connect to the FewoWorld mint site, Paint Holders can move their Paint Drop NFTs off of Nifty and onto a non-custodial wallet i.e. MetaMask, Coinbase Wallet, etc.</p>
                    <p>How to move NFTs off NiftyGateway <a href="https://help.niftygateway.com/hc/en-us/articles/4406321767955-How-do-I-withdraw-my-NFT" target="_blank" rel="noreferrer">here</a>.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Will certain Paint traits have effect on the Fewos appearance (doodles, linework, embroidery, etc)</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Paint Holders can equip their Paint Drops to their Fewos to give the Fewo DNA that matches their Paint Drop. This will display on the NFT as a Paint Drop in the FEWOs hand and their heart or bones will change to match their Paint Drop.</p>
                  </div>
                </div>

                <h2 className="faq-section-heading">Flowers</h2>
                <div className="faq-qa">
                  <h3>Are Flowers going to be their own NFTs?</h3>
                  <div className="faq-qa-answer-wrapper">
                    <p>Yes, Flowers are individual ERC 1155’s and will “Bloom” into FewoFashion wearables. While they’re in their flower-state they can be equipped to your FEWO and held in their hand.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Will it cost gas to move things into my Fewo’s backpack?</h3>
                  <div className="faq-qa-answer-wrapper">
                    <p>Yes.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Will flowers be burned upon redemption of a wearable or accessory?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Once minted, Fewos will be eligible to redeem flowers that will be used as mint passes to “bloom” into FewoFashion digital wearables and accessories.</p>
                    <p>For example: If you wanted a FewoFashion “Tuxedo Jacket”, you would have to exchange a Flower for it.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Do flowers have rarity?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Each flower tier will have an initially-set rarity, which is distributed as more common (“common daisy”) to less (“elusive pixelflower”). However, during the flower exchange, that rarity curve can change. Regardless of rarity; the tier of the flower will be associated with the tier of wearables or items it will be able to redeem. Wearables and items within each tier will vary.</p>
                  </div>
                </div>  
                <div className="faq-qa">
                  <h3>When are Flowers getting airdropped?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>All Flowers will be airdropped to holders to their original minting wallet on October 5th between 1pm - 3pm EST.</p>
                  </div>
                </div>
                {/*<div className="faq-qa">
                  <h3>How many Flowers can I exchange to get an extra FEWO?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <ul>
                      <li>5 Common Daisies = 1 Additional Fewo Mint</li>
                      <li>4 Rare Sunflowers  = 1 Additional Fewo Mint</li>
                      <li>3 Epic Roses  = 1 Additional Fewo Mint</li>
                      <li>2 Legendary Forget Me Nots  = 1 Additional Fewo Mint</li>
                      <li>1 Elusive Pixelflower  = 1 Additional Fewo Mint</li>
                    </ul>
                  </div>
                </div>*/}
                <div className="faq-qa">
                  <h3>Can I bundle Flowers from other Paint Drops to exchange for an extra FEWO? </h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Flowers used to exchange for additional Fewos must come from the same Paint Drop. Paint Drop holders with 10 Paint or more in their Paint Drop are the first to qualify for extra Fewos. If you have multiple drops in the same wallet on mint, the mint site will show you how many total Fewos you are eligible to exchange for.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>How are Flower tier distributions decided?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Paint Holders receive 1 flower for every drop of Paint in their Paint Drop. The distribution of the different types of flowers is done on a curve to ensure that as Paint Drops get bigger, the distribution of tiers gets better. As you exchange flowers for extra Fewos, the decrease in flowers flows to maintain a consistent distribution to ensure holders have as many tiers possible as their flower numbers go down.</p>
                  </div>
                </div>
               {/* <div className="faq-qa">
                  <h3>What is the Flower Calculator for?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>The Flower Calculator will help holders gauge how many Flowers they want to exchange to redeem additional Fewos. It doesn’t lock in your decision for mint day, but is a helpful planning tool. </p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>How long will the calculator be available?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>The calculator tool will be open until mint day. You can use it <a href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">here</a>.</p>
                  </div>
                </div> */}   
                <div className="faq-qa">
                  <h3>What are the different tiers of flowers and what do they mean?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Flowers are mint passes for future wearables and items in FewoWorld. Those items will fall into tiers that match those of the flowers. For instance, you will need an Epic tiered flower to redeem for an item in the Epic tier of wearables, and so on.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>Do I need to transfer all my Flowers to my Backpack immediately after Mint?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>You can transfer Flowers into your Backpack whenever you want! If you want to equip your Flower as a holdable to your Fewo, then it will need to be in its Backpack.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>When can I redeem my Flowers for Wearables?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>The first season of FewoFashion wearables will be released in December. In the meantime, once the dressing room is launched, you’ll be able to equip and unequip holdable items (Paint & Flowers) as well as Canvas (as a necklace/chain) to your Fewo.</p>
                  </div>
                </div>                                 

                <h2 className="faq-section-heading">Canvas</h2>                
                <div className="faq-qa">
                  <h3>Will more Canvases continue to get distributed to attendees of future Paint Parties?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Canvas is a free air drop to commemorate attendance at Paint Parties and will continue to get distributed! Canvas NFTs from every Paint Party are also air dropped to the Top 50 Paint Holders.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>What will Canvas do for Fewos?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Canvas NFTs will allow for access to the FEWOCiOUS Art Holders Mint. Canvas will also be equipable to your Fewos - when you equip your Canvas NFT to your Fewo it becomes a wearable chain!</p>
                  </div>
                </div>     

                <h2 className="faq-section-heading">Dressing Room</h2>                  
                <div className="faq-qa">
                  <h3>Do I need to have an account on FewoWorld.io to access the Dressing Room?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Yes, you’ll need to set up an account in order to access the Dressing Room and any future applications on the site.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>How do I use the Dressing Room?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Once you’ve selected a Fewo and gone to their Dressing Room, you can “Add Tokens” from your connected wallet into your Fewo’s backpack. When the Dressing Room launches on November 29th, you’ll be able to add Paint, Flowers or Canvas tokens to their backpack. To equip a token, you’ll select it and see the green check mark and border appear on the token, then you’ll click “Save Changes” to change your PFP. This could take up to 3 minutes depending on how many items you’re equipping or the complexity of your render. To unequip an item, just deselect it to make the green checkmark and border disappear and click “Save Changes”.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>What does “Standing View” do?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>You can view your Fewo, with their changes, in full body Standing View. This image can be saved from the website. Your PFP will not update to a standing position.</p>
                  </div>
                </div>  
                <div className="faq-qa">
                  <h3>I only see a few categories listed in my backpack - should I see more?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Token categories get updated with drops. As of now, only Holdables (Paint and Flowers) and Accessories (Canvas Chain) are available.</p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>How does the identification check work?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>The ID check for the FewoWorld account is set up for the protection of FewoWorld Inc. and of the consumer. Jumio is the provider of FewoWorld’s ID check services and the personal information gathered from the ID check is not stored after the check has been completed. Only a “Passed” or “Failed” value is saved in the internal systems associated with the checks. If you’re ID check fails and you feel this was an error, please try again on another device or contact <a href="mailto:support@fewoworld.io">support@fewoworld.io</a></p>
                  </div>
                </div>
                <div className="faq-qa">
                  <h3>What happens when I transfer a Fewo with items in it’s backpack to another wallet?</h3>
                  <div className="faq-qa-answer-wrapper">                  
                    <p>Items in a Fewo’s backpack go with the Fewo when transferred. When transferring any Fewos to another wallet, always be sure to remove any NFTs in its backpack that you do not want transferred as well. Best practices is to refresh the metadata of your Fewo on Opensea after removing items.</p>
                  </div>
                </div>                                
              </Cell>
            </Grid>                        
          </div>
        </section>            
       </div>
    </HelmetProvider>

  );
}
export default App;