// Styles
import './styles/App.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Cell } from 'react-foundation'
// import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionHeader from './components/SectionHeader';
import SectionWelcome from './components/SectionWelcome';
import SectionInfo from './components/SectionInfo';
import SectionPaintIntro from './components/SectionPaintIntro';
import SectionPaintColored from './components/SectionPaintColored';
import { Link } from "react-router-dom";
import Slider from "react-slick";
// const helmetData = new HelmetData({});
// import Model from './components/Paint'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function initCanvasBase(section, canvas) {
  let text = section.querySelector(".text");
  let context = canvas.getContext("2d");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;  

  let frameCount = 100;
  const currentFrame = index => (
    `/paint-base-webp/Base_${(index + 1).toString().padStart(4, '0')}.webp`
  );

  let images = []
  let paintdrops = {
    frame: 0
  };
  
  for (let i = 0; i < frameCount; i++) {
    let img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  const handleResize = e => {
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    render()
  };

  handleResize();
  window.addEventListener("resize", handleResize);

  // console.log('paintbase scrolltrigger');
  gsap.timeline({
    onUpdate: render,
    scrollTrigger: {
      trigger: section,
      pin: true,
      // scrub: 0.4,
      end: "+=350%",
      immediateRender: false,
      toggleActions: "resume pause resume pause"
      // markers: true
    }
  })
  .to(text, {
    opacity: 1,
    x: -100,
    duration: 0.4
  })  
  .to(paintdrops, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    // duration: 0.4,
    duration: 3,
    repeat: -1
  }, 0);
  
  images[0].onload = render;

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);

    let dim = (800 < window.innerHeight) ? 800 : window.innerHeight;
    let left = 50;
    let top = (window.innerHeight - dim) / 2;

    if (window.innerWidth > 2000) {
      left = (window.innerWidth/10)
    }

    if (window.innerWidth < 1024) {
      dim = window.innerWidth * .6;
      left = 0;
      top = (window.innerHeight - dim) / 2;
    }

    if (window.innerWidth < 640) {
      dim = window.innerWidth;
      left = 0;
      top = 0;
    }

    context.drawImage(images[paintdrops.frame], left, top, dim, dim); 
  }  
}

// function initCanvasColored(section, canvas) {
//   let text = section.querySelector(".text");
//   let context = canvas.getContext("2d");
//   canvas.width = window.innerWidth;
//   canvas.height = window.innerHeight;  

//   let frameCount = 100;
//   const currentFrame = index => (
//     `/paint-colored-webp/Colored_${(index + 1).toString().padStart(4, '0')}.webp`
//   );

//   let images = []
//   let paintdrops = {
//     frame: 0
//   };
  
//   for (let i = 0; i < frameCount; i++) {
//     let img = new Image();
//     img.src = currentFrame(i);
//     images.push(img);
//   }

//   const handleResize = e => {
//     canvas.height = window.innerHeight;
//     canvas.width = window.innerWidth;
//     render()
//   };

//   handleResize();
//   window.addEventListener("resize", handleResize);

//   console.log('paintcolor scrolltrigger');
//   gsap.timeline({
//     onUpdate: render,
//     scrollTrigger: {
//       trigger: section,
//       pin: true,
//       // scrub: 0.4,
//       end: "+=350%",
//       immediateRender: false,
//       toggleActions: "resume pause resume pause"
//       // markers: true
//     }
//   })
//   .to(text, {
//     opacity: 1,
//     x: -100,
//     duration: 0.4
//   })  
//   .to(paintdrops, {
//     frame: frameCount - 1,
//     snap: "frame",
//     ease: "none",
//     duration: 3,
//     repeat: -1
//   }, 0);
  
//   images[0].onload = render;

//   function render() {
//     context.clearRect(0, 0, canvas.width, canvas.height);

//     let dim = (800 < window.innerHeight) ? 800 : window.innerHeight;
//     let left = (window.innerWidth / 2) - (dim / 4);
//     let top = (window.innerHeight - dim) / 2;

//     if (window.innerWidth < 1024) {
//       dim = window.innerWidth * .6;
//       left = (window.innerWidth / 2) - (dim / 4);
//       top = (window.innerHeight - dim) / 2;
//     }

//     if (window.innerWidth < 640) {
//       dim = window.innerWidth;
//       left = 0;
//       top = 0;
//     }    

//     context.drawImage(images[paintdrops.frame], left, top, dim, dim); 
//   } 
   
// }

function initCanvasEmbroidered(section, canvas) {
  let text = section.querySelector(".text");
  let context = canvas.getContext("2d");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;  

  let frameCount = 100;
  const currentFrame = index => (
    `/paint-embroidered-webp/Embroidered_${(index + 1).toString().padStart(4, '0')}.webp`
  );

  let images = []
  let paintdrops = {
    frame: 0
  };
  
  for (let i = 0; i < frameCount; i++) {
    let img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  const handleResize = e => {
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    render()
  };

  handleResize();
  window.addEventListener("resize", handleResize);
  // console.log('paintembroider scrolltrigger');
  gsap.timeline({
    onUpdate: render,
    scrollTrigger: {
      trigger: section,
      pin: true,
      // scrub: 0.4,
      end: "+=350%",
      immediateRender: false,
      toggleActions: "resume pause resume pause"
      // markers: true
    }
  })
  .to(text, {
    opacity: 1,
    x: -100,
    duration: 0.4
  })  
  .to(paintdrops, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    // duration: 0.4,
    duration: 3,
    repeat: -1    
  }, 0);
  
  images[0].onload = render;
  
  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);

    let dim = (800 < window.innerHeight) ? 800 : window.innerHeight;
    let left = (window.innerWidth / 2) - (dim / 4);
    let top = (window.innerHeight - dim) / 2;

    if (window.innerWidth < 1024) {
      dim = window.innerWidth * .6;
      left = (window.innerWidth / 2) - (dim / 4);
      top = (window.innerHeight - dim) / 2;
    }

    if (window.innerWidth < 640) {
      dim = window.innerWidth;
      left = 0;
      top = 0;
    }     

    context.drawImage(images[paintdrops.frame], left, top, dim, dim); 
  } 

  // function render() {
  //   context.clearRect(0, 0, canvas.width, canvas.height);

  //   context.drawImage(images[paintdrops.frame], (window.innerWidth / 2), 0, 800, 800); 
  // }  
}

function initCanvasNft(section, canvas) {
  let text = section.querySelector(".text");
  let context = canvas.getContext("2d");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;  

  let frameCount = 60;
  const currentFrame = index => (
    `/canvas-nft-webp/Canvas_${(index + 1).toString().padStart(4, '0')}.webp`
  );

  let images = []
  let paintdrops = {
    frame: 0
  };
  
  for (let i = 0; i < frameCount; i++) {
    let img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  const handleResize = e => {
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    render()
  };

  handleResize();
  window.addEventListener("resize", handleResize);
  // console.log('canvasnft scrolltrigger');
  gsap.timeline({
    onUpdate: render,
    scrollTrigger: {
      trigger: section,
      pin: true,
      scrub: 2,
      end: "+=200%",
      immediateRender: false
      // markers: true
    }
  })
  .to(text, {
    opacity: 1,
    x: -100,
    duration: 0.5
  })  
  .to(paintdrops, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    duration: 2
  }, 0);
  
  images[0].onload = render;

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);

    let dim = window.innerHeight;
    let left = 0;
    let top = 0;

    if (window.innerWidth < 1024) {
      dim = window.innerHeight;
      left = -100;
      top = 0;
    }

    if (window.innerWidth < 640) {
      dim = window.innerHeight;
      left = -250;
      top = 0;
    }     

    context.drawImage(images[paintdrops.frame], left, top, dim, dim); 
  }  
}

function initOther(section, pinval) {
  let text = section.querySelector(".description");
  // console.log(section, 'what am i runnin')
  if (text) {  
    // console.log('pinnedtext scrolltrigger');
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        pin: pinval,
        end: "+=200%",
        // scrub: 0.4,
        immediateRender: false
        // markers: true
      }
    })    
    .to(text, {
      opacity: 1,
      // y: -100,
      scale: 1.35,
      duration: 0.4
    }) 
  } else {
    // console.log('pinned scrolltrigger');
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        pin: pinval,
        end: "+=200%",
        // scrub: 0.4,
        immediateRender: false
        // markers: true
      }
    })        
  }
}

function App(props) {
  const [logoActive, setLogoActive] = useState(false)
  const [canvasLoaded, setCanvasLoaded] = useState(false)
  const [headerLoaded, setHeaderLoaded] = useState(false)
  const [startLoad, setStartLoad] = useState(false)
  const [welcomeLoaded, setWelcomeLoaded] = useState(false)
  const [infoLoaded, setInfoLoaded] = useState(false)
  const [paintIntroLoaded, setPaintIntroLoaded] = useState(false)
  const [paintColoredLoaded, setPaintColoredLoaded] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)
  const [slideCount, setSlideCount] = useState(0)
  let containerClass = logoActive ? 'App App--active' : 'App'
  let contentClass = logoActive ? 'fw-content fw-content--active grid-container section-two' : 'fw-content grid-container section-two'

  // constructor(props) {
  //   super(props);
  //   this.next = this.next.bind(this);
  //   this.previous = this.previous.bind(this);
  // }
  // next() {
  //   this.slider.slickNext();
  // }
  // previous() {
  //   this.slider.slickPrev();
  // }


  useEffect(()=>{
    if(window.location.hash) {
      console.log(window.location.hash)
    } else {

    }

    if (!startLoad) {
      document.body.classList.add('lock');
      setStartLoad(true)
    }
  }, [startLoad])

  useEffect(()=> {
    if (!canvasLoaded 
      && headerLoaded 
      && welcomeLoaded 
      && infoLoaded 
      && paintIntroLoaded 
      && paintColoredLoaded) {

      document.body.classList.remove('lock');
      ScrollTrigger.clearScrollMemory();
      window.history.scrollRestoration = "manual"
      // console.log('this should only run once')
      
      let sections = gsap.utils.toArray("section");
      sections.forEach(section => {  
        let canvas = section.querySelector("canvas");
        let canvasColored = section.querySelector(".colored");
        let canvasBase = section.querySelector(".base");
        let canvasEmbroidered = section.querySelector(".embroided");
        let canvasNft = section.querySelector(".canvas-nft");

        if (canvas && canvasColored) {
          // initCanvasColored(section, canvas)

        } else if (canvas && canvasBase) {
          initCanvasBase(section, canvas)

        } else if (canvas && canvasEmbroidered) {
          initCanvasEmbroidered(section, canvas)

        } else if (canvas && canvasNft) {
          initCanvasNft(section, canvas)

        } else {
          // let pin = section.querySelector(".pin")
          // let pinval = pin ? true : false
          // initOther(section, pinval)
        }
      });

      // console.log('parallax scrolltrigger');
      // console.log(-ScrollTrigger.maxScroll(window), 'maxScroll');
      // setTimeout(function (){

      let bgs = gsap.utils.toArray(".parallax-bg");
        bgs.forEach(bg => {  
        gsap.timeline({
          scrollTrigger: {
          scrub: true,
          immediateRender: false
          // markers: true
        }
        })    
        .to(bg, {
          y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed * 0.25,
          ease: "none"
        }) 
      })

      // gsap.timeline({
      //   scrollTrigger: {
      //     scrub: true,
      //     // immediateRender: false
      //     // markers: true
      //   }
      // })    
      // .to(".App-header", {
      //   y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed * 0.25,
      //   ease: "none"
      // })         
      // gsap.to(".parallax-bg", {
      //   scrollTrigger: {
      //     scrub: true,
      //     // refreshPriority: -1,
      //   }, 
      //   y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
      //   ease: "none"
      // });

      // gsap.to(".App-header", {
      //   scrollTrigger: {
      //     scrub: true,
      //     // refreshPriority: -1,
      //   }, 
      //   y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
      //   ease: "none"
      // });  
      
      gsap.to(".painted", {
        autoAlpha: 1,
        duration: 5,
        scrollTrigger: {
          trigger: ".paint-marker",
          start: "top center",
          // refreshPriority: -1,
          // end: "top 100px",
          // scrub: true,
          // markers: true,
          // id: "scrub"
        }
      });    

      gsap.utils.toArray(".lazy").forEach(image => {
        let newSRC = image.dataset.src,
          newImage = document.createElement("img"),
            
        lazyLoadImg = () => {
          newImage.onload = () => {
            newImage.onload = null; // avoid recursion
            newImage.src = image.src; // swap the src
            image.src = newSRC;
            // place the low-res version on TOP and then fade it out.
            gsap.set(newImage, {
              position: "absolute", 
              top: image.offsetTop, 
              left: image.offsetLeft, 
              width: image.offsetWidth, 
              height: image.offsetHeight
            });
            image.parentNode.appendChild(newImage);
            gsap.to(newImage, {
              opacity: 0, 
              onComplete: () => newImage.parentNode.removeChild(newImage)
            });
            st && st.kill();
          }
          newImage.src = newSRC;
        }, 
            
        st = ScrollTrigger.create({
          trigger: image,
          start: "-100% bottom",
          onEnter: lazyLoadImg,
          onEnterBack: lazyLoadImg // make sure it works in either direction
        });
      });        

      // setTimeout(() => {
      //   ScrollTrigger.refresh();
      //   console.log('refresh')
      // }, 6000) 

      setCanvasLoaded(true)
    }
  }, [canvasLoaded, headerLoaded, welcomeLoaded, infoLoaded, paintIntroLoaded, paintColoredLoaded])

  function handleHeaderCallback (childData) {
    setHeaderLoaded(childData);
  }

  function handleWelcomeCallback (childData) {
    setWelcomeLoaded(childData);
  } 

  function handleInfoCallback (childData) {
    setInfoLoaded(childData);
  }  

  function handlePaintIntroCallback (childData) {
    setPaintIntroLoaded(childData);
  } 

  function handlePaintOneCallback (childData) {
    setPaintColoredLoaded(childData);
  }   

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", backgroundImage: `url('next.png')` }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", backgroundImage: `url('prev.png')` }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />      
  };         

  // function handleLogoClick(e) {
  //   e.preventDefault();
  //   setLogoActive(true);
  //   document.body.classList.add('scroll');
  // }


  // const executeScroll = (section) => {
  //   handleNavState();
  //   gsap.to(window, {duration: 1, scrollTo:{y:`#${section}`, offsetY:-(window.innerHeight/2) - 500}});
  // }

  // const [navState, setNavState] = useState(false);

  // const handleNavState = () => {
  //   if (!navState) {
  //     setNavState(true)
  //   } else {
  //     setNavState(false)      
  //   }
  // }

  // let navMobMenuClass = navState ? 'App-nav App-nav--mobile App-nav--mobile--active' : 'App-nav App-nav--mobile'
  // let navMobMenuBtnClass = navState ? 'App-menu App-menu--active' : 'App-menu'

  return (
    <HelmetProvider >
      <Helmet>
        <title>FewoWorld</title>
        <meta name="description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta name="theme-color" content="#e3c20d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FewoWorld" />
        <meta property="og:description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta property="og:image" content={'fewoworld-og-image.jpg'} />        
      </Helmet>        
      <div className={containerClass}>
        
        <SectionHeader canvasLoaded={canvasLoaded} parentCallback={handleHeaderCallback}  />
        
        <SectionWelcome headerLoaded={headerLoaded} parentCallback={handleWelcomeCallback} />
       
        <SectionInfo welcomeLoaded={welcomeLoaded} parentCallback={handleInfoCallback} />

        <div className="paint-marker" />
          
        <SectionPaintIntro infoLoaded={infoLoaded} parentCallback={handlePaintIntroCallback} />
           
        <SectionPaintColored paintIntroLoaded={paintIntroLoaded} parentCallback={handlePaintOneCallback} />



        <section className="paint-section-two">
          <canvas className="base"></canvas>
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-right">
              <Cell small={12} medium={6}>
                <div className="text">
                  <p>Holders of <span className="dont-wrap"><span className="fewo-quotation fewo-quotation--left">“</span>Paint Drops<span className="fewo-quotation">”</span></span> will be able to eventually unlock things in FewoWorld, including Canvas and Fewos.</p>
                </div>              
              </Cell>
            </Grid>
          </div>
        </section> 
        <section className="paint-section-three">
          <canvas className="embroided"></canvas>
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x">
              <Cell small={12} medium={6}>
                <div className="text">
                  <p>The more Paint in your <span className="dont-wrap"><span className="fewo-quotation fewo-quotation--left">“</span>Paint Drop<span className="fewo-quotation">”</span></span>, the more you’ll be able to unlock in FewoWorld. </p>
                  <a href="https://opensea.io/collection/fewoworld-paint" target="_blank" rel="noreferrer" className="buy-paint-btn" style={{backgroundImage: `url('buy-button-pink.png')`}}>View Paint</a>
                </div>              
              </Cell>
            </Grid>
          </div>
        </section> 
        {/*<section className="ranking-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={12} large={8}>
                <h2>Rankings</h2>
                <span className="original-sale-banner" style={{backgroundImage: `url('coming-soon-bg.png')`}}>Original Sale</span>
              </Cell>
            </Grid>
            <Grid className="grid-x grid-padding-x align-center slider-outer-wrapper">
              <Cell small={12} medium={12} large={10} className="slider-wrapper">
                <Slider {...settings}>
                  <div key={1}>
                    <img alt="Paint Rankings 1-10" src={'rankings/rankings-1.png'} />
                  </div>
                  <div key={2}>
                    <img alt="Paint Rankings 11-20" src={'rankings/rankings-2.png'} />
                  </div>
                  <div key={3}>
                    <img alt="Paint Rankings 21-30" src={'rankings/rankings-3.png'} />
                  </div>
                  <div key={4}>
                    <img alt="Paint Rankings 31-40" src={'rankings/rankings-4.png'} />
                  </div>
                  <div key={5}>
                    <img alt="Paint Rankings 41-50" src={'rankings/rankings-5.png'} />
                  </div>                  
                </Slider>                               
              </Cell>
            </Grid>
          </div>
        </section>*/}
        <section id="canvas" className="canvas-nft-section">
          <canvas className="canvas-nft"></canvas>
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-right">
              <Cell small={12} medium={12} large={6}>
                <div className="text">
                  <img alt="Canvas" src={'canvas-nft-logo.png'}/>
                   <p className="canvas-nft-section__paragraph">Canvas is a reward for top Paint holders and Paint Party attendees.</p>
                   {/*}<p className="canvas-nft-section__note">Canvas arrives October 13th!</p>{*/}
                   <Link to={'/canvas'} className="see-canvas-btn" style={{backgroundImage: `url(view-canvas.png)`}}></Link>
                  {/*}
                  <span className="coming-soon" style={{backgroundImage: `url('coming-soon-bg.png')`}}>Coming Soon</span>
                  {*/}
                </div>              
              </Cell>
            </Grid>
          </div>
        </section>                    
        <section id="fewos" className="fewos-inline-section">
          <Grid className="grid-x grid-padding-x align-center fewos-full-image-large">
            <Cell small={12} medium={8} large={8}>
              <img className="fewos-logo" src={'/fewos-logo-full.png'} loading="lazy" alt="FEWOs Logo" />
            </Cell>
            <Cell small={12} medium={8} large={8}>
              <p>Fewos are the inhabitants of FewoWorld. <span>18,000</span> unique characters created through the cosmic explosion of Paint.</p>
            </Cell>            
          </Grid>          
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x fewos-more-coming-soon">
                <Cell small={12} medium={8} large={8}>
                  <h4 className="fewos-grass-text-highlight">The <span>5</span> Types of Flowers</h4>                  
                  <p style={{marginBottom: "2rem"}}>There are different tiers of flowers that will have access to different tiers of Fewo Fashion items. The more paint in your paint drop, the more high tier flowers you’ll get.</p>
                </Cell> 
                <Cell small={12} medium={12} large={12}>
                  <div className="fewos-flower-types">
                    <div className="fewo-flower-type">
                      <img alt="Common Daisy" loading="lazy" src={'/flower-1.jpg'} />
                      <span className="fewos-grass-text-highlight">Common<br/>Daisies</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Rare Sunflower" loading="lazy" src={'/flower-2.jpg'} />                      
                      <span className="fewos-grass-text-highlight">Rare<br/>Sunflowers</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Epic Rose" loading="lazy" src={'/flower-3.jpg'} />
                      <span className="fewos-grass-text-highlight">Epic<br/>Roses</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Legendary Forget Me Not" loading="lazy" src={'/flower-4.jpg'} />
                      <span className="fewos-grass-text-highlight">Legendary<br/>Forget Me Nots</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Elusive Pixelflower" loading="lazy" src={'/flower-5.jpg'} />                      
                      <span className="fewos-grass-text-highlight">Elusive<br/>Pixelflowers</span>                      
                    </div>
                  </div>
                </Cell> 
                <Cell small={12} medium={8} large={8}>
                  <h4 className="fewos-grass-text-highlight">Top Paint Holders</h4>  
                  <div className="fewos-flower-exchange-text">
                    {/*<p>Top Paint Holders will also get <span>1</span> Diamond Flower trophy each, which will be redeemable for a future Diamond collectors edition fashion item.</p>*/}
                    <p>Top Paint Holders can visit the link below to answer prompts about their Custom Fewos.</p>                  
                    <a href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">Custom Fewo Form</a>                  
                  </div>
                </Cell>          
            </Grid>
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-dressing-room">
              <Cell small={12} medium={8} large={8}>
                  <img className="fewos-dressing-room-logo" loading="lazy" src={'/dressing-room-logo.png'} alt="Dressing Room" />
                  <p>Starting November <span>29</span>th, you’ll have access to your Fewo’s Backpack and to the Fewo Dressing Room!</p>
                  <p>By creating an account and logging in, you’ll be able to connect your wallet with your Fewos, select a Fewo, and then equip and unequip items in the Dressing Room to change your PFP!</p>
                  <p style={{marginBottom: "3rem"}}>You can experiment with Flowers, Paint Drops and Canvas until the first collection of wearables launch in December.</p>
                  <p>Log In to View Your Fewos Dashboard</p>
                  <a className="button" href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">LOG IN</a>                  
              </Cell>
            </Grid>                        
          </div>
          {/*<div className="grid-container">
           <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={6}>
                <h2 className="fewos-characters__heading">FEWO's</h2>
                <p className="pin">Fewos are the cute lil' inhabitants of FewoWorld.</p>
                <div>
                  <Link to={'/fewos'} className="buy-paint-btn" style={{backgroundImage: `url('buy-button-pink.png')`}}>Learn More</Link>              
                </div>
              </Cell>
              <Cell small={12} medium={6}>
                <img alt="Fewos" className="lazy" src={'fewos-character.png'} />
              </Cell>
            </Grid>
          </div>  */}
        </section>

        <section className="big-clouds">
          <img alt="Clouds" className="lazy" data-src={'big-clouds.png'} />
        </section>

        <section className="ground-world">
          <video className="intro-section__video" autoPlay loop muted playsInline src="https://player.vimeo.com/external/692623460.hd.mp4?s=3a3296ac7abea9296de9627c46ff7a1dd67fdc2a&profile_id=169" type="video/mp4"></video>
        </section>

        <section id="no-roadmap" className="mind-map">
          <img alt="Mindmap" className="lazy" data-src={'mindmap.webp'} />
        </section>

        <section id="lore" className="lore-section">
          <div className="lore-section__heading">
            <h2>The Lore</h2>
          </div>
          <img alt="The Lore" className="lazy" data-src={'lore-notebook-page.webp'} />
        </section>      

        {/*<section className="team">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={8}>
                <h2>Team</h2>
              </Cell>
            </Grid>
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={6} medium={4}>
                <a href="http://twitter.com/fewocious" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image"style={{backgroundImage: `url('/team/Fewo-1-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>FEWOCiOUS</h3>
                      <span>Creator</span>
                    </div>
                  </div>
                </a>
              </Cell>              
              <Cell small={6} medium={4}>
                <a href="http://twitter.com/richerd" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/Richerd-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>Richerd<span>(Manifold)</span></h3>
                      <span>Web3 Development</span>
                    </div>
                  </div>
                </a>
              </Cell>              
              <Cell small={6} medium={4}>
                <a href="http://twitter.com/logangardner3d" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/Logan-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>Logan</h3>
                      <span>3D & Generative Guru</span>
                    </div>
                  </div>
                </a>
              </Cell> 
              <Cell small={6} medium={4}>
                <a href="https://twitter.com/nostalgic_dream" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/Aaron-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>Aaron</h3>
                      <span>Frontend Badass</span>
                    </div>
                  </div>
                </a>
              </Cell>              
              <Cell small={6} medium={4}>
                <a href="http://twitter.com/farokh" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/Farokh-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>Farokh</h3>
                      <span>Chief Vibes Officer</span>
                    </div>
                  </div>
                </a>
              </Cell>
              <Cell small={6} medium={4}>
                <a href="https://twitter.com/jameshalldon" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/James-PFP.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>James</h3>
                      <span>Discord Moderator</span>
                    </div>
                  </div>
                </a>
              </Cell>                                                     
              <Cell small={6} medium={4}>
                <a href="http://twitter.com/permanentarts" target="_blank" rel="noreferrer">
                  <div className="team__member">
                    <div className="team__member-image" style={{backgroundImage: `url('/team/PERM-ARTS.jpg')`}}></div>
                    <div className="team__member-info">
                      <h3>Permanent Arts</h3>
                      <span>Studio & Project Management</span>
                    </div>
                  </div>
                </a>
              </Cell>                                                                      
            </Grid>
          </div>
        </section>*/}

        <section className="footer">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={8}>
                <div className="footer__logo-wrapper">
                  <img alt="FEWOCiOUS" src={'fewocious-logo.png'} />
                </div>
                <div className="footer__divider" />
                <div className="footer__social">
                  <div className="footer__social-link footer__social-link--twitter" style={{backgroundImage: `url('twitter.png')`}}>
                    <a href="https://twitter.com/fewoworld" target="_blank" rel="noreferrer"></a>
                  </div>
                  <div className="footer__social-link footer__social-link--instagram" style={{backgroundImage: `url('instagram.png')`}}>
                    <a href="https://instagram.com/fewocious" target="_blank" rel="noreferrer"></a>
                  </div>
                  <div className="footer__social-link footer__social-link--discord" style={{backgroundImage: `url('discord.png')`}}>
                    <a href="https://discord.com/invite/wfHtcz5Pm5" target="_blank" rel="noreferrer"></a>
                  </div>
                  <div className="footer__social-link footer__social-link--youtube" style={{backgroundImage: `url('youtube.png')`}}>
                    <a href="https://www.youtube.com/channel/UCmJPFfiUNWJWXh4cZnQ-clA" target="_blank" rel="noreferrer"></a>
                  </div>
                </div>
                <div className="footer__copyright-row footer__copyright-row--link">
                  <a href="https://fewos.fewoworld.io/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
                  <a href="https://fewos.fewoworld.io/terms" target="_blank" rel="noreferrer">Terms and Conditions</a>
                </div>
                <div className="footer__copyright-row">
                  <span>Copyright 2023 FewoWorld, LLC</span>
                </div>
              </Cell>
            </Grid>
          </div>
        </section>

        {/*}
        <div className={navMobMenuBtnClass} onClick={handleNavState} style={{backgroundImage: `url('menu-button-texture-yellow.jpg')`}}>
          <img className="menu-icon" src={'menu.svg'} />
          <img className="close-icon" src={'close.svg'} />
        </div>
        <nav className={navMobMenuClass} style={{backgroundImage: `url('mobile-nav-bg.jpg')`}}>
          <img className="App-logo-mobile" src={'fewoworld-logo-paper.png'} />
          <ul className="App-nav__list">
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--purple.png')`}}>
              <button onClick={() => executeScroll('about')}>About</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--yellow.png')`}}>
              <button onClick={() => executeScroll('paint')}>Paint</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--green.png')`}}>
              <button onClick={() => executeScroll('canvas')}>Canvas</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--orange.png')`}}>
              <button onClick={() => executeScroll('fewos')}>Fewos</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--red.png')`}}>
              <button onClick={() => executeScroll('mindmap')}>Mindmap</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--purple.png')`}}>
              <button onClick={() => executeScroll('mindmap')}>Lore</button>
            </li>
            <li className="App-nav__list-item" style={{backgroundImage: `url('smudge-bg--green.png')`}}>
              <a href="https://fewocious.com" target="_blank" rel="noreferrer">FEWOCiOUS</a>
            </li>                                                                                       
          </ul>
        </nav>
        {*/}

        <div id="parallax-bg-5" className="parallax-bg painted" data-speed=".05" style={{backgroundImage: `url('fewo-world-bg-paint-cloud.webp')`}} />
        <div id="parallax-bg-4" className="parallax-bg" data-speed=".05" style={{backgroundImage: `url('fewo-world-bg-light-cloud.webp')`}} />
        <div id="parallax-bg-3" className="parallax-bg" data-speed=".75">
          <div id="bg-3-1" style={{backgroundImage: `url('fewo-car.png')`}}></div>
          <div id="bg-3-2"></div>
          <div id="bg-3-3"></div>
          
        </div>
        <div id="parallax-bg-2" className="parallax-bg" data-speed=".4">
          <div id="bg-2-1" style={{backgroundImage: `url('clouds1.png')`}}></div>
          <div id="bg-2-2" style={{backgroundImage: `url('clouds1.png')`}}></div>
          <div id="bg-2-3" style={{backgroundImage: `url('clouds1.png')`}}></div> 
          <div id="bg-2-4" style={{backgroundImage: `url('clouds1.png')`}}></div>
          <div id="bg-2-5" style={{backgroundImage: `url('clouds1.png')`}}></div>
          <div id="bg-2-6" style={{backgroundImage: `url('clouds1.png')`}}></div>
        </div>
        <div id="parallax-bg-1" className="parallax-bg" data-speed=".25">
          <div id="bg-1-1" style={{backgroundImage: `url('clouds2.png')`}}></div>
          <div id="bg-1-2" style={{backgroundImage: `url('clouds3.png')`}}></div>
          <div id="bg-1-3" style={{backgroundImage: `url('clouds2.png')`}}></div>
          <div id="bg-1-4" style={{backgroundImage: `url('clouds3.png')`}}></div>
          <div id="bg-1-5" style={{backgroundImage: `url('clouds2.png')`}}></div>
        </div>                
      </div>
    </HelmetProvider>
  );
}

export default App;
