import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CityPage from './City';
import CanvasPage from './CanvasPage';
import CanvasArchive from './CanvasArchive';
import LegalPage from './LegalPage';
import FewosPage from './Fewos';
import FaqsPage from './Faqs';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App/>} />       
        <Route exact path="canvas" element={<CanvasPage/>}>       
          <Route path=":cityId" element={<CityPage/>} />       
          <Route index element={<CanvasArchive/>} />       
        </Route>
        <Route path="/fewos" element={<FewosPage/>} />              
        <Route path="/legal" element={<LegalPage/>} />       
        <Route path="/faq" element={<FaqsPage/>} />       
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
