// Styles
import './styles/App.scss'

import React, { useState, useEffect } from 'react'
import { Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function App() {
  const [loaded, setLoaded] = useState(false);

  // const [days, setDays] = useState(0)
  // const [hours, setHours] = useState(0)
  // const [minutes, setMinutes] = useState(0)
  // const [seconds, setSeconds] = useState(0)

  // useEffect(()=>{
  //   const target = new Date(1695700800000)

  //   const interval = setInterval(()=>{
  //     const now = new Date();
  //     const difference = target.getTime() - now.getTime()
  //     const d = Math.floor(difference / (1000 * 60 * 60 * 24))
  //     setDays(d)

  //     const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //     setHours(h)

  //     const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
  //     setMinutes(m)

  //     const s = Math.floor((difference % (1000 * 60)) / (1000))
  //     setSeconds(s)                  

  //   }, 1000)

  //   return () => clearInterval(interval)

  // },[])

  const executeScroll = (section, showMenu) => {

    let offsetY = 0;
    if (section === 'about') {
      offsetY = -300;
    }
    if (section === 'canvas') {
      offsetY = -300;
    }
    gsap.to(window, {duration: 1, scrollTo:{y:`#${section}`, offsetY:offsetY}});
  }

  // function changeBackground(e) {
  //   let city = e.target.getAttribute("data-city");
  //   e.target.style.backgroundImage = `url('/cities/` + city + `-2.png')`;
  //   e.target.classList.toggle("active");
  // }  

  // function changeBackgroundBack(e) {
  //   let city = e.target.getAttribute("data-city");
  //   e.target.style.backgroundImage = `url('/cities/` + city + `-1.png')`;
  //   e.target.classList.toggle("active");    
  // } 

  useEffect(() => {
    if (!loaded){  
      document.body.classList.add('body-canvas');
      window.scrollTo(0, 0);
      setLoaded(true)
    }
  },[loaded]);

  // const { cityId } = useParams();
  // console.log(cityId, 'cityId')

  return (
    <HelmetProvider >
      <Helmet>
        <title>FewoWorld</title>
        <meta name="description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta name="theme-color" content="#e3c20d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FewoWorld" />
        <meta property="og:description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta property="og:image" content={'fewoworld-og-image.jpg'} />        
      </Helmet> 
      <div className="standard-page fewos-root-wrapper" style={{backgroundImage: `url('/fewos-bg-fullpage-1.jpg')`}}>
        <section className="inline-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
                {/*<Cell small={12} medium={6} large={6}>
                  <Link to={'/'} className="logo-link-wrapper"><img className="App-logo" src={'/fewoworld-logo-paper@2x.png'} /></Link>
                </Cell>*/}
               <Cell small={12} medium={12} large={12}>
                 <div className="fewos-nav-items">
                   <Link to={'/#about'} className="fewos-nav-item fewos-nav-item--about"><img className="fewos-nav-icon fewos-nav-icon--about" src={'/fewos-nav-about.png'} alt="About" /></Link>
                   <Link to={'/#paint'} className="fewos-nav-item fewos-nav-item--paint"><img className="fewos-nav-icon fewos-nav-icon--paint" src={'/fewos-nav-paint.png'} alt="Paint" /></Link>
                   <Link to={'/#canvas'} className="fewos-nav-item fewos-nav-item--canvas"><img className="fewos-nav-icon fewos-nav-icon--canvas" src={'/fewos-nav-canvas.png'} alt="Canvas" /></Link>
                   {/*<Link to={'/#fewos'} className="fewos-nav-item fewos-nav-item--fewos"><img className="fewos-nav-icon fewos-nav-icon--fewos" src={'/fewos-nav-fewos.png'} alt="Fewos" /></Link>*/}
                   <Link to={'/#no-roadmap'} className="fewos-nav-item fewos-nav-item--no-roadmap"><img className="fewos-nav-icon fewos-nav-icon--no-roadmap" src={'/fewos-nav-no-roadmap.png'} alt="No Roadmap" /></Link>
                   <Link to={'/#lore'} className="fewos-nav-item fewos-nav-item--lore"><img className="fewos-nav-icon fewos-nav-icon--lore" src={'/fewos-nav-lore.png'} alt="Lore" /></Link>
                   <Link to={'/faq'} className="fewos-nav-item fewos-nav-item--fewocious"><img className="fewos-nav-icon fewos-nav-icon--fewocious" src={'/fewos-nav-faq.png'} alt="FAQs" /></Link>
                   <a href="https://fewos.fewoworld.io" rel="noreferrer" target="_blank" className="fewos-nav-item fewos-nav-item--fewocious"><img className="fewos-nav-icon fewos-nav-icon--fewocious" src={'/fewos-mint-nav.png'} alt="Fewos Mint" /></a>
                 </div>
                </Cell>                
            </Grid>
          </div>          
        </section>
        <section id="fewos-page" className="fewos-page-wrapper">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center fewos-full-image-large">
              <Cell small={12} medium={12} large={12}>
                <img className="fewos-logo" src={'/fewos-logo-full.png'} alt="FEWOs Logo" />
              </Cell>
            </Grid>                                      

            <Grid className="grid-x grid-padding-x align-center fewos-text-description">
              <Cell small={12} medium={10} large={10}> 
                {/*<a href="http://fewos.fewoworld.io/" target="_blank" className="fewos-mint-link">
                  <img className="fewos-launching-soon d" src={'/fewos-mint-button-link.png'} alt="Fewos Mint" />
                </a>*/}
                {/*<img className="fewos-launching-soon d" src={'/launching-september.png'} alt="Launching Sept" />*/}
                {/*<div className="fewos-countdown-wrapper">
                  <div className="fewos-countdown-image">
                    <img src={'/countdown-clock.svg'} alt="countdown clock" />
                  </div>   
                  <div className="fewos-countdown">
                    <div className="fewos-countdown-column"><span style={{backgroundImage: `url('/box-1.svg')`}} className="fewos-countdown-number">{days}</span></div>
                    <div className="fewos-countdown-column"><span style={{backgroundImage: `url('/box-1.svg')`}} className="fewos-countdown-number">{hours}</span></div>
                    <div className="fewos-countdown-column"><span style={{backgroundImage: `url('/box-1.svg')`}} className="fewos-countdown-number">{minutes}</span></div>
                    <div className="fewos-countdown-column"><span style={{backgroundImage: `url('/box-1.svg')`}} className="fewos-countdown-number">{seconds}</span></div>
                  </div>
                </div>*/}
                
                {/*<div className={'fewos-new-info-click'} onClick={() => executeScroll('fewos-page-holders')}><img className="fewos-coming-soon-overlay" src={'/new-info-announced.svg'} alt="FEWOs Coming Sept" /></div>*/}
                <p className="fewos-description">Fewos are the inhabitants of FewoWorld. <span>18,000</span> unique characters created through the cosmic explosion of Paint.</p>
              </Cell>
            </Grid> 
            <Grid className="grid-x grid-padding-x align-center fewos-ways-to-get-wrapper fewos-full-image-large">
              <Cell small={12} medium={12} large={12}>                     
                <img className="fewos-paint-sketch" src={'/fewos-cosmic-explosion-art.png'} alt="FEWOs Explosion" />
              </Cell>                            
            </Grid>              
            <Grid className="grid-x grid-padding-x align-center fewos-sketch-wrapper">
              <Cell small={12} medium={12} large={12}>            
                <p className="fewos-however-text">However, Fewos are not your typical PFP project, you see.</p>
              </Cell>
            </Grid>
            <Grid className="grid-x grid-padding-x align-center fewos-pfp-wrapper">
              <img className="fewos-eye-sketch-1" src={'/fewos-eye-sketch.png'} alt="FEWOs Eye 1" />
              <img className="fewos-eye-sketch-2" src={'/fewos-eye-sketch.png'} alt="FEWOs Eye 2" />
              <Cell small={12} medium={12} large={12}>                     
                <img className="fewos-paint-sketch" src={'/fewos-3species.png'} alt="FEWOs Explosion" />
              </Cell>                            
              <Cell small={8} medium={4} large={4}>            
                <div className="fewos-pfp-circle" style={{backgroundImage: `url('/fewos-circle-1.svg')`}}><img className="fewos-pfp" src={'/fewo-humanoid-2.jpg'} alt="FEWOs - Frankenstein" /></div>
                <h4>Frankenstein</h4>
              </Cell>
              <Cell small={8} medium={4} large={4}>            
                <div className="fewos-pfp-circle" style={{backgroundImage: `url('/fewos-circle-2.svg')`}}><img className="fewos-pfp" src={'/fewo-humanoid-4.jpg'} alt="FEWOs - Misunderstood" /></div>
                <h4>Misunderstood</h4>                
              </Cell>              
              <Cell small={8} medium={4} large={4}>            
                <div className="fewos-pfp-circle" style={{backgroundImage: `url('/fewos-circle-3.svg')`}}><img className="fewos-pfp" src={'/fewo-humanoid-3.jpg'} alt="FEWOs - Humanoid" /></div>
                <h4>Humanoid</h4>                
              </Cell>                            
            </Grid>     
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={4} large={4}>                     
                <img className="fewos-down-arrow" src={'/fewos-down-arrow.png'}  alt="FEWOs Down Arrow" />
              </Cell>                            
            </Grid> 
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-dna-wrapper">
              <Cell small={12} medium={6} large={6}>   
                <div className="fewos-dna-doodles">
                  <img className="fewos-bone-sketch" src={'/fewos-bone-sketch.png'} alt="FEWOs Bone" />                  
                  <img className="fewos-dna-sketch" src={'/fewos-dna-sketch.png'} alt="FEWOs DNA" />                  
                </div>
                <p>Every Fewo has its own unique set of traits and characteristics, that come embedded in their DNA.</p>
                <p>From squiggly eyes, to linework bones, and everything in between, these creatures were born from the mind of FEWOCiOUS, with every trait hand drawn and then converted into beautiful <span>3</span>D art.</p>
              </Cell>                            
              <Cell small={12} medium={6} large={6}>
                {/*<img className="fewos-transformation-sketch" src={'/fewos-sketch.jpg'} alt="FEWOs tranformation" />*/}
                <video className="fewos-transformation-sketch" autoPlay loop muted playsInline src="https://player.vimeo.com/progressive_redirect/playback/831663909/rendition/720p/file.mp4?loc=external&signature=d72d1f963f474e176d082c6ec52519a98b406b6f3b3ed4bebb7c6108bc59602e" type="video/mp4"></video>
              </Cell>              
            </Grid>  
          </div>
        </section>
        <section id="fewos-page-holders" className="fewos-page-wrapper" style={{backgroundImage: `url('/fewo-world-bg-light-cloud.jpg')`}}>  
          <img className="fewos-down-paper-rip" src={'/fewos-ripped-paper-down-transparent-3.png'}  alt="FEWOs - Paper Rip" />                   
          <div className="grid-container">                                    
            <Grid className="grid-x grid-padding-x align-center fewos-ways-to-get-wrapper fewos-full-image-large">
              <Cell small={12} medium={12} large={12}>                     
                <div className="fewos-ways-to-get-image-wrapper">
                  <img className="fewos-ways-to-get" src={'/fewo-ways-to-get.png'} alt="FEWOs Ways to Get" />
                </div>
                <img className="fewos-its-true" src={'/fewos-its-true.png'} alt="FEWOs Doodle" />
              </Cell>                            
            </Grid> 
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-paint-wrapper">
              <Cell small={12} medium={5} large={5}>
                <img className="fewos-paint" src={'/paint-colored-webp/Colored_0001.webp'} alt="FEWOs - Paint" />
              </Cell>              
              <Cell small={12} medium={6} large={6}>                     
                <img className="fewos-holder-title" src={'/fewos-paint-holder-airdrop.png'} alt="FEWOs - Paint Holder" />              
                <p className="fewos-cloud-text-highlight">Paint holders will be airdropped their <span>1</span> free Fewo.</p>
                <div className="fewos-new-info">
                  {/*<img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />*/}
                  <span>Sept <span className="date-number">26</span>th, <span className="date-number">1</span>-<span className="date-number">3</span>pm EST</span>
                </div>                
              </Cell>                            
            </Grid>             
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-paint-wrapper">
              <Cell small={12} medium={5} large={5}>
                <img className="fewos-paint" src={'/paint-base-webp/Base_0001.webp'} alt="FEWOs - Paint" />
              </Cell>              
              <Cell small={12} medium={6} large={6}>                     
                <img className="fewos-holder-title fewos-holder-title--full" src={'/fewo-flower-exchange-mint.png'} alt="FEWOs - Paint Holder" />   
                {/*<p className="fewos-holder-subtitle">(Flower Exchange)</p>           */}
                <p className="fewos-cloud-text-highlight">Paint Holders can use Flowers if they have <span>10</span> or more Paint in <span>1</span> Drop, to redeem additional Fewos during this window. Additional info <button onClick={() => executeScroll('flower-calculator')}>HERE</button></p>
                <div className="fewos-new-info">
                  {/*<img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />*/}
                  <span>Sept <span className="date-number">27</span>th, <span className="date-number">12</span>-<span className="date-number">10</span>pm EST<br/>FREE</span>
                </div>
                <p className="fewos-presale-mint-note">Gas and other fees may apply</p>
              </Cell>                            
            </Grid> 
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-canvas-wrapper">
              <Cell small={12} medium={5} large={5}>                     
                <img className="fewos-holder-title fewos-holder-title--large" src={'/fewos-art-mint.png'}  alt="FEWOs - Art Holder" />                            
                <p className="fewos-cloud-text-highlight">If you hold Fewo Art, canvas, or previously released wearables, you can access the Fewo Art Holders Presale at a discounted price.</p>
                <div className="fewos-new-info">
                  {/*<img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />*/}
                  <span>Sept <span className="date-number">28</span>th, <span className="date-number">1</span>pm-<span className="date-number">12</span>am EST<br/>.<span className="date-number">08</span> ETH<br/><Link to={'/faq#pricing'}>Refer to Pricing Mechanics</Link></span>
                </div>                
                <p className="fewos-presale-mint-note">Gas and other fees may apply</p>                
              </Cell>                            
              <Cell small={12} medium={6} large={6}>
                <img className="fewos-canvas" src={'/fewos-canvas.gif'}  alt="FEWOs - Canvas Holder" />
              </Cell>              
            </Grid>
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-public-mint-wrapper">
              <Cell small={12} medium={6} large={6}>
                <img className="fewos-public-drawing" src={'/fewos-public-sale-drawing.png'} alt="FEWOs - Public Sale" />
              </Cell>             
              <Cell small={12} medium={5} large={5}>                     
                <img className="fewos-holder-title fewos-holder-title--large" src={'/fewos-public-mint.png'} alt="FEWOs - Public Sale Graphic" />                            
                <div className="fewos-cloud-text-highlight">
                  <p>The general public will have access to the public sale.</p>
                  <div className="fewos-new-info">
                    <img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />
                    <span>Sept <span className="date-number">29</span>th,<br/>.<span className="date-number">25</span> ETH<br/><Link to={'/faq#pricing'}>Refer to Pricing Mechanics</Link></span>
                  </div>                  
                  <div className="fewos-public-mint-note">
                    <ul>
                      <li><span className="date-number">10</span> am EST - Nifty Gateway Mint <span className="fewos-minting-on">(on Nifty Gateway - limited suppy)</span></li>
                      <li><span className="date-number">12</span> pm EST - Allowlist Mint <span className="fewos-minting-on">(on FewoWorld Website)</span></li>
                        <ul>
                          <li>nft now (Now Pass)</li>
                          <li>Rug Radio</li>
                          <li>VeeFriends (Mint Mink Tokens)</li>
                        </ul>
                      <li><span className="date-number">2</span> pm  - <span className="date-number">4</span> pm EST - Public Mint <span className="fewos-minting-on">(on FewoWorld Website)</span></li>
                    </ul>
                  </div>
                  <p className="fewos-public-mint-note">Gas & other fees may apply</p>
                </div>
              </Cell>                            
            </Grid>
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-public-mint-wrapper fewos-public-mint-wrapper-reveal">
              {/*<Cell small={12} medium={6} large={6}>
                <img className="fewos-public-drawing" src={'/3-flowers.png'} alt="Flower Airdrop" />
              </Cell>*/}             
              <Cell small={12} medium={5} large={5}>                     
                <img className="fewos-holder-title fewos-holder-title--large" src={'/fewo-mint-reveal.png'} alt="Flower Airdrop" />                            
                <div className="fewos-cloud-text-highlight">
                  <div className="fewos-new-info fewos-new-info--centered">
                    {/*<img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />*/}
                    <span>Oct <span className="date-number">2</span>nd <br/><span className="date-number">1</span>-<span className="date-number">2</span>pm EST</span>
                  </div>                                  
                </div>
              </Cell>                            
            </Grid>      
           <Grid className="grid-x grid-padding-x align-center align-middle fewos-public-mint-wrapper">
              <Cell small={12} medium={5} large={5}>                     
                <img className="fewos-holder-title fewos-holder-title--large" src={'/flower-airdrop.png'} alt="Flower Airdrop" />                            
                <div className="fewos-cloud-text-highlight">
                  <p>ALL FLOWERS WILL BE AIR DROPPED AFTER THE PUBLIC SALE CLOSES.</p>
                  <div className="fewos-new-info">
                    {/*<img className="fewos-new-info-img" src={'/new-info.svg'} alt="New Info" />*/}
                    <span>Oct <span className="date-number">5</span>th<br/><span className="date-number">1</span>-<span className="date-number">3</span>pm EST</span>
                  </div>                                  
                </div>
              </Cell>                            
              <Cell small={12} medium={6} large={6}>
                <img className="fewos-public-drawing" src={'/3-flowers.png'} alt="Flower Airdrop" />
              </Cell>             
            </Grid>            
          </div>
        </section>
        <section id="fewos-wallet-section" className="fewos-page-wrapper" style={{backgroundImage: `url('/fewos-grass-texture-1.png')`}}>  
          <div className="grid-container">                                            
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-full-image-large">
              <Cell small={12} medium={12} large={12}>
                <img className="fewos-wallet-image" src={'/fewos-your-fewos-backpack.png'} alt="FEWOs - Backpack" />
              </Cell> 
            </Grid> 
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-backpack-wrapper">
              <Cell small={12} medium={8} large={8}>                     
                <p className="fewos-wallet-text fewos-grass-center-text-highlight">FEWOs will come with their own individual ERC-<span>6551</span> <span className="dont-wrap"><span className="fewo-quotation fewo-quotation--left">“</span>backpack<span className="fewo-quotation">”</span>!</span></p>
                <p className="fewos-wallet-text fewos-wallet-text--small fewos-grass-center-text-highlight">Fewo holders will be air dropped flowers that will be used as mint passes to <span className="fewo-quotation-wrap"><span className="fewo-quotation fewo-quotation--left">“</span>bloom<span className="fewo-quotation">”</span></span> into FewoFashion digital wearables and accessories.</p>
              </Cell>
              <Cell small={12} medium={6} large={6}>
                <img className="fewos-backpack-drawing" src={'/fewos-flower-backpack.png'}  alt="FEWOs - Backpack Graphic" />
              </Cell>                           
            </Grid>
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-flower-wrapper">
              <Cell small={12} medium={6} large={5}>                     
                 <video className="fewos-flower-image" autoPlay loop muted playsInline src="https://player.vimeo.com/progressive_redirect/playback/831661987/rendition/720p/file.mp4?loc=external&signature=08f8867f011cabbe7f4be84bd23ed2b4411d52bc932864a48c01c577f780efc1" type="video/mp4"></video>
              </Cell>                            
              <Cell small={12} medium={6} large={6} offsetOnLarge={1}>
                <img className="fewos-plus-backpack" src={'/fewo-plus-backpack.png'} alt="FEWOs plus Backpack" />                
                <p className="fewos-grass-text-highlight">Paint Holders are eligible for <span>1</span> Free Flower for every unit of paint in their paint drop and non-paint drop holders are eligible for <span>1</span> free flower per Fewo minted.</p>
                {/*<p className="fewos-grass-text-highlight">Paint Drop holders will be eligible to redeem <span>1</span> free flower for every unit of paint in their Paint Drop once their Fewo is minted.</p>*/}
                <p className="fewos-grass-text-highlight">All Flowers will be air dropped after public sale closes.</p>
              </Cell>              
            </Grid>
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-more-coming-soon">
              <Cell small={12} medium={12} large={12}>
                  <img className="fewos-flowers-know" src={'/get-to-know-ur-flowers.svg'}  alt="Get to know your flowers" />
              </Cell> 
              <Cell small={12} medium={8} large={8}>
                  <p className="fewos-grass-text-highlight">There are different tiers of flowers that will have access to different tiers of Fewo Fashion items. The more paint in your paint drop, the more high tier flowers you’ll get.</p>
              </Cell> 
              <Cell small={12} medium={8} large={8}>
                  <h4 className="fewos-grass-text-highlight">The <span>5</span> Types of Flowers</h4>                  
              </Cell> 
              <Cell small={12} medium={12} large={12}>
                  <div className="fewos-flower-types">
                    <div className="fewo-flower-type">
                      <img alt="Common Daisy" src={'/flower-1.jpg'} />
                      <span className="fewos-grass-text-highlight">Common<br/>Daisies</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Rare Sunflower" src={'/flower-2.jpg'} />                      
                      <span className="fewos-grass-text-highlight">Rare<br/>Sunflowers</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Epic Rose" src={'/flower-3.jpg'} />
                      <span className="fewos-grass-text-highlight">Epic<br/>Roses</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Legendary Forget Me Not" src={'/flower-4.jpg'} />
                      <span className="fewos-grass-text-highlight">Legendary<br/>Forget Me Nots</span>
                    </div>
                    <div className="fewo-flower-type">
                      <img alt="Elusive Pixelflower" src={'/flower-5.jpg'} />                      
                      <span className="fewos-grass-text-highlight">Elusive<br/>Pixelflowers</span>                      
                    </div>
                  </div>
              </Cell> 
              <Cell small={12} medium={8} large={8}>
                  <h4 className="fewos-grass-text-highlight">Top Paint Holders</h4>  
                  <div className="fewos-flower-exchange-text">
                    <p>Top Paint Holders will also get <span>1</span> Diamond Flower trophy each, which will be redeemable for a future Diamond collectors edition fashion item.</p>
                    <p>Top Paint Holders can visit the link below to answer prompts about their Custom Fewos.</p>                  
                    <a href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">CUSTOM FEWO FORM</a>                  
                  </div>
              </Cell>

              <Cell id="flower-calculator" small={12} medium={8} large={8}>
                  <img className="fewos-holder-title" src={'/flower-exchange-calculator-big.png'} alt="Flower Exchange Calculator" />              
              </Cell> 

              <Cell small={12} medium={8} large={8} >
                <div className="fewos-flower-exchange-text">
                  <p className="fewos-grass-text-highlight">For Paint Holders Only: If you have <span>10</span> Paint or more in your Paint Drop you can exchange some of your flowers for additional Fewos during the Paint Holder Flower Exchange.</p>
                  <p>It’s completely up to the holder how many of your eligible extra Fewos you want to mint!</p>              
                  <p className="fewos-grass-text-highlight">To see how many of each Flower you’ll get, and if you’re eligible to mint additional fewos, use the Flower Exchange Calculator below.</p>
                  <a href="https://fewos.fewoworld.io" target="_blank" rel="noreferrer">FLOWER EXCHANGE CALCULATOR</a>                  
                  {/*<img className="fewos-flower-calculator-image" src={'/flower-calculator.png'} alt="flower Calculator" />*/}
                </div>
              </Cell>                                            
              
              {/*<Cell small={12} medium={8} large={8}>                     
                <div className="fewos-flowers-legend">
                  <li><span>5</span> Common Daiseys = <span>1</span> Additional Fewo Mint</li> 
                  <li><span>4</span> Rare Sunflowers  = <span>1</span> Additional Fewo Mint</li> 
                  <li><span>3</span> Epic Roses  = <span>1</span> Additional Fewo Mint</li> 
                  <li><span>2</span> Legendary Forget Me Nots  = <span>1</span> Additional Fewo Mint</li> 
                  <li><span>1</span> Elusive Pixelflower  = <span>1</span> Additional Fewo Mint</li>                 
                </div>
              </Cell>*/}                            
            </Grid>                                             
          </div>
        </section>
        <section id="fewos-dressing-room-section" className="fewos-page-wrapper" style={{backgroundImage: `url('/wood-texture-1.png')`}}>
          <div className="grid-container">  
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-dressing-room">
              <Cell small={12} medium={8} large={8}>
                   <img className="fewos-dressing-room-logo" src={'/dressing-room-logo.png'} alt="Dressing Room" />
                  <p>In November (date TBA), you’ll have access to your Fewo’s Backpack and to the Fewo Dressing Room!</p>
                  <p className="fewos-wood-text-highlight">By connecting your wallet that contains your Fewos, and then selecting which Fewo, you’ll be able to equip and unequip Fewo Fashion & other FewoWorld items to and from your PFP.</p>
              </Cell>
            </Grid>       
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-faq-link">
              <Cell small={12} medium={8} large={8}>
                <h3>FewoWorld FAQs</h3>
                <Link to={'/faq'} className="button">Read FAQs</Link>
              </Cell>
            </Grid>              
            <Grid className="grid-x grid-padding-x align-center align-middle fewos-take-care">
              <Cell small={12} medium={6} large={5}>
                <div className="">
                  <img className="fewos-take-care-graphic" src={'/take-care-logo-white.png'} alt="Take Care" />
                </div>
              </Cell>
            </Grid>                     
          </div>
        </section>        
       </div>
    </HelmetProvider>

  );
}
export default App;