import React, {useState, useEffect} from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Button, Grid, Cell } from 'react-foundation'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SectionWelcome = ({infoLoaded, parentCallback}) => {
  // const [loaded, setLoaded] = useState(false)
  const [prevLoad, setPrevLoad] = useState(false)
  const [imagesRequested, setImagesRequested] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageLoadedArray, setImageLoadedArray] = useState([]);

  // Define Images
  useEffect(() => {
    if (!imagesRequested) {
      let images = {
        paint_logo: {
          url: '/paint.png'
        }
      }

      for (const item in images) {
        images[item]['loaded'] = false;
      }

      loadSectionImages(images)
      setImagesRequested(true)
    }
  }, [imagesRequested])

  // Load each image
  const loadSectionImages = (images) => {
    for (const item in images) {
      let img = new Image();
      img.src = images[item]['url'];
      img.onload = () => {     
        images[item]['loaded'] = true
        setImageLoadedArray(images)
      }
    }
  }

  // Check if all are loaded
  useEffect(() => {
    let allLoaded = allTrue(imageLoadedArray);
    if (allLoaded) {
      setImagesLoaded(true)    
    } 
  }, [imageLoadedArray])

  const allTrue = (obj) => {
    if (obj.length == 0) 
      return false

    for(var img in obj) {
        if (imageLoadedArray[img].loaded == false) {
          return false;
        }
    }

    return true;
  }

  useEffect(() => {
    // console.log(infoLoaded, 'infoLoaded')
    if (infoLoaded == true) {
      setPrevLoad(true)
    }
  }, [infoLoaded])

  // Load GSAP and tell parent
  useEffect(() => {
    if (imagesLoaded && prevLoad) {
      // console.log('gsap 4')

      gsap.timeline({
        scrollTrigger: {
          trigger: "#paint",
          pin: true,
          end: "+=200%",
          // scrub: 0.4,
          immediateRender: false
          // markers: true
        }
      })    
      .to(".paint-section-intro__description", {
        opacity: 1,
        // y: -100,
        scale: 1.35,
        duration: 0.4
      }) 

      // setLoaded(true)
      parentCallback(true) 
    }
  }, [imagesLoaded, prevLoad])

  let paint_logo = typeof imageLoadedArray['paint_logo'] !== "undefined" ? imageLoadedArray['paint_logo']['url'] : ''

  return (
    <section id="paint" className="paint-section-intro">
      <Grid className="grid-x grid-padding-x align-center App-header__inner">
        <Cell small={12} medium={8}>
          <div className="paint-section-intro__inner">
            <img src={paint_logo} />
            <p className="paint-section-intro__description description pin">
              This is a paint drop.
            </p>
          </div>
        </Cell>
      </Grid>        
    </section> 
  )
}

export default SectionWelcome;