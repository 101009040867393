// Styles
import './styles/App.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Outlet } from "react-router-dom";

function App() {
  const [loaded, setLoaded] = useState(false);

  // function changeBackground(e) {
  //   let city = e.target.getAttribute("data-city");
  //   e.target.style.backgroundImage = `url('/cities/` + city + `-2.png')`;
  //   e.target.classList.toggle("active");
  // }  

  // function changeBackgroundBack(e) {
  //   let city = e.target.getAttribute("data-city");
  //   e.target.style.backgroundImage = `url('/cities/` + city + `-1.png')`;
  //   e.target.classList.toggle("active");    
  // } 

  useEffect(() => {
    if (!loaded){  
      document.body.classList.add('body-canvas');
      window.scrollTo(0, 0);
      setLoaded(true)
    }
  },[loaded]);

  return (
    <section id="paint-city" className="city-section city-section--archive">
      <div className="grid-container">
        <Grid className="grid-x grid-padding-x align-center">
          <Cell small={12} medium={6} large={6}>
            <h2>Locations</h2>
            <p>Browse the locations below</p>
          </Cell>
        </Grid>
        <Grid className="grid-x grid-padding-x align-center">
          <Cell className="city-section__city-column" small={6} medium={4} large={4}>
            <Link to={'/canvas/nyc21'} className="city-section__city" data-city="nyc21">
              <img className="city-image" src={'/cities/nyc21-1.png'} />
              <img className="city-image-hover" src={'/cities/nyc21-2.png'} />
            </Link>
          </Cell>
          <Cell className="city-section__city-column" small={6} medium={4} large={4}>
            <Link to={'/canvas/miami21'} className="city-section__city" data-city="miami">
              <img className="city-image" src={'/cities/miami-1.png'} />
              <img className="city-image-hover" src={'/cities/miami-2.png'} />              
            </Link>
          </Cell>
          <Cell className="city-section__city-column" small={6} medium={4} large={4}>
            <Link to={'/canvas/la22'} className="city-section__city" data-city="la">
              <img className="city-image" src={'/cities/la-1.png'} />
              <img className="city-image-hover" src={'/cities/la-2.png'} />                            
            </Link>
          </Cell>  
          <Cell className="city-section__city-column" small={6} medium={4} large={4}>
            <Link to={'/canvas/mn22'} className="city-section__city" data-city="minnesota">
              <img className="city-image" src={'/cities/minnesota-1.png'} />
              <img className="city-image-hover" src={'/cities/minnesota-2.png'} />                                          
            </Link>
          </Cell>
          <Cell className="city-section__city-column" small={6} medium={4} large={4}>
            <Link to={'/canvas/nyc22'} className="city-section__city" data-city="nyc22">
              <img className="city-image" src={'/cities/nyc22-1.png'} />
              <img className="city-image-hover" src={'/cities/nyc22-2.png'} />                
            </Link>
          </Cell>                                          
        </Grid>
        <Grid className="grid-x grid-padding-x align-center">
          <Cell small={12} medium={10} large={10}>
            <p className="canvas-note">Canvas is a free air drop to Paint Party attendees and Top Paint Holders (The current holders of the Top 50 Paint Drops from the initial Paint sale on Nifty Gateway*). "Canvas" is a digital representation of a section of a real painted canvas from a Paint Party. Each Canvas NFT will be distinguishable by Paint Party - to date there will be Canvas NFTs from NFT NYC 2021, Art Basel Miami 2021, NFT LA 2022, VeeCon 2022 and NFT NYC 2022. Each Paint Party attendee is air dropped 1 Canvas NFT from the Paint Party they attended and the current Top Paint Holders will receive a bundle of all 5 Canvas NFTs (1 for each location so far). No two NFTs will display the same fragment of canvas, making them all fully unique. The full physical canvases and an allocation of Canvas NFTs as reserves, will remain in the FewoWorld vault.</p>
            <p className="canvas-note canvas-note--subsubnote">Copyright © 2022 FewoWorld. Terms & Conditions <Link to={'/legal'}>HERE</Link>.</p>                
          </Cell>
        </Grid>            
      </div>
</section>  

  );
}
export default App;