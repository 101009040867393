const cities = [
  {
    name: "NYC21",
    id: "nyc21",
    description: "A paint party for NYC 21",
    canvases: [
      {
        name: "Canvas 1",
        id: "1",
        image: "/canvases/1_NYC21.jpg",
        start: "1",
        width: "2",
        height: "6"
      },
      {
        name: "Canvas 2",
        id: "2",
        image: "/canvases/2_NYC21.jpg",
        start: "13",
        width: "2",
        height: "6"        
      },
      {
        name: "Canvas 3",
        id: "3",
        image: "/canvases/3_NYC21.jpg",
        start: "25",
        width: "2",
        height: "6"        
      },
      {
        name: "Canvas 4",
        id: "4",
        image: "/canvases/4_NYC21.jpg",
        start: "37",
        width: "2",
        height: "6"        
      },
      {
        name: "Canvas 5",
        id: "5",
        image: "/canvases/5_NYC21.jpg",
        start: "49",
        width: "2",
        height: "6"        
      }, 
      {
        name: "Canvas 6",
        id: "6",
        image: "/canvases/6_NYC21.jpg",
        start: "61",
        width: "2",
        height: "6"        
      },        
      {
        name: "Canvas 7",
        id: "7",
        image: "/canvases/7_NYC21.jpg",
        start: "73",
        width: "2",
        height: "5"        
      },        
      {
        name: "Canvas 8",
        id: "8",
        image: "/canvases/8_NYC21.jpg",
        start: "83",
        width: "2",
        height: "5"        
      },        
      {
        name: "Canvas 9",
        id: "9",
        image: "/canvases/9_NYC21.jpg",
        start: "93",
        width: "2",
        height: "5"        
      },  
      {
        name: "Canvas 10",
        id: "10",
        image: "/canvases/10_NYC21.jpg",
        start: "103",
        width: "2",
        height: "5"        
      },
      {
        name: "Canvas 11",
        id: "11",
        image: "/canvases/11_NYC21.jpg",
        start: "113",
        width: "2",
        height: "4"        
      },
      {
        name: "Canvas 12",
        id: "12",
        image: "/canvases/12_NYC21.jpg",
        start: "121",
        width: "2",
        height: "4"        
      },
      {
        name: "Canvas 13",
        id: "13",
        image: "/canvases/13_NYC21.jpg",
        start: "129",
        width: "2",
        height: "4"        
      },
      {
        name: "Canvas 14",
        id: "14",
        image: "/canvases/14_NYC21.jpg",
        start: "137",
        width: "2",
        height: "4"        
      },
      {
        name: "Canvas 15",
        id: "15",
        image: "/canvases/15_NYC21.jpg",
        start: "145",
        width: "2",
        height: "4"        
      },
      {
        name: "Canvas 16",
        id: "16",
        image: "/canvases/16_NYC21.jpg",
        start: "153",
        width: "2",
        height: "4"        
      },

    ]
  },
  {
    name: "Miami21",
    id: "miami21",
    description: "A paint party for Miami 21",
    canvases: [
      {
        name: "Canvas 17",
        id: "17",
        image: "/canvases/17_MIAMI21.jpg",
        start: "161",
        width: "2",
        height: "6"
      },
      {
        name: "Canvas 18",
        id: "18",
        image: "/canvases/18_MIAMI21.jpg",
        start: "173",
        width: "2",
        height: "6"
      },      
      {
        name: "Canvas 19",
        id: "19",
        image: "/canvases/19_MIAMI21.jpg",
        start: "185",
        width: "2",
        height: "6"
      },
      {
        name: "Canvas 20",
        id: "20",
        image: "/canvases/20_MIAMI21.jpg",
        start: "197",
        width: "2",
        height: "6"
      },        
      {
        name: "Canvas 21",
        id: "21",
        image: "/canvases/21_MIAMI21.jpg",
        start: "209",
        width: "2",
        height: "6"
      },           
      {
        name: "Canvas 22",
        id: "22",
        image: "/canvases/22_MIAMI21.jpg",
        start: "221",
        width: "2",
        height: "6"
      },           
      {
        name: "Canvas 23",
        id: "23",
        image: "/canvases/23_MIAMI21.jpg",
        start: "233",
        width: "2",
        height: "6"
      },          
      {
        name: "Canvas 24",
        id: "24",
        image: "/canvases/24_MIAMI21.jpg",
        start: "245",
        width: "2",
        height: "5"
      },          
      {
        name: "Canvas 25",
        id: "25",
        image: "/canvases/25_MIAMI21.jpg",
        start: "255",
        width: "2",
        height: "5"
      },                
      {
        name: "Canvas 26",
        id: "26",
        image: "/canvases/26_MIAMI21.jpg",
        start: "265",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 27",
        id: "27",
        image: "/canvases/27_MIAMI21.jpg",
        start: "273",
        width: "2",
        height: "4"
      },           
      {
        name: "Canvas 28",
        id: "28",
        image: "/canvases/28_MIAMI21.jpg",
        start: "281",
        width: "4",
        height: "2"
      }                                                  
    ]
  },
  {
    name: "LA22",
    id: "la22",
    description: "A paint party for Miami 21",
    canvases: [
      {
        name: "Canvas 29",
        id: "29",
        image: "/canvases/29_LA22.jpg",
        start: "289",
        width: "3",
        height: "9"
      },
      {
        name: "Canvas 30",
        id: "30",
        image: "/canvases/30_LA22.jpg",
        start: "316",
        width: "3",
        height: "9"
      },
      {
        name: "Canvas 31",
        id: "31",
        image: "/canvases/31_LA22.jpg",
        start: "343",
        width: "3",
        height: "9"
      },
      {
        name: "Canvas 32",
        id: "32",
        image: "/canvases/32_LA22.jpg",
        start: "370",
        width: "3",
        height: "9"
      },      
      {
        name: "Canvas 33",
        id: "33",
        image: "/canvases/33_LA22.jpg",
        start: "397",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 34",
        id: "34",
        image: "/canvases/34_LA22.jpg",
        start: "407",
        width: "2",
        height: "5"
      },       
      {
        name: "Canvas 35",
        id: "35",
        image: "/canvases/35_LA22.jpg",
        start: "417",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 36",
        id: "36",
        image: "/canvases/36_LA22.jpg",
        start: "427",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 37",
        id: "37",
        image: "/canvases/37_LA22.jpg",
        start: "437",
        width: "2",
        height: "5"
      }, 
      {
        name: "Canvas 38",
        id: "38",
        image: "/canvases/38_LA22.jpg",
        start: "447",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 39",
        id: "39",
        image: "/canvases/39_LA22.jpg",
        start: "455",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 40",
        id: "40",
        image: "/canvases/40_LA22.jpg",
        start: "463",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 41",
        id: "41",
        image: "/canvases/41_LA22.jpg",
        start: "471",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 42",
        id: "42",
        image: "/canvases/42_LA22.jpg",
        start: "479",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 43",
        id: "43",
        image: "/canvases/43_LA22.jpg",
        start: "487",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 44",
        id: "44",
        image: "/canvases/44_LA22.jpg",
        start: "495",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 45",
        id: "45",
        image: "/canvases/45_LA22.jpg",
        start: "503",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 46",
        id: "46",
        image: "/canvases/46_LA22.jpg",
        start: "511",
        width: "2",
        height: "4"
      }                  
    ]
  },
  {
    name: "MN22",
    id: "mn22",
    description: "A paint party for VeeCon 22",
    canvases: [
      {
        name: "Canvas 47",
        id: "47",
        image: "/canvases/47_VEE22.jpg",
        start: "519",
        width: "2",
        height: "6"
      },
      {
        name: "Canvas 48",
        id: "48",
        image: "/canvases/48_VEE22.jpg",
        start: "531",
        width: "2",
        height: "6"
      },      
      {
        name: "Canvas 49",
        id: "49",
        image: "/canvases/49_VEE22.jpg",
        start: "543",
        width: "2",
        height: "6"
      },
      {
        name: "Canvas 50",
        id: "50",
        image: "/canvases/50_VEE22.jpg",
        start: "555",
        width: "2",
        height: "6"
      }, 
      {
        name: "Canvas 51",
        id: "51",
        image: "/canvases/51_VEE22.jpg",
        start: "567",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 52",
        id: "52",
        image: "/canvases/52_VEE22.jpg",
        start: "577",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 53",
        id: "53",
        image: "/canvases/53_VEE22.jpg",
        start: "587",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 54",
        id: "54",
        image: "/canvases/54_VEE22.jpg",
        start: "595",
        width: "2",
        height: "5"
      },
      {
        name: "Canvas 55",
        id: "55",
        image: "/canvases/55_VEE22.jpg",
        start: "605",
        width: "2",
        height: "4"
      },
      {
        name: "Canvas 56",
        id: "56",
        image: "/canvases/56_VEE22.jpg",
        start: "613",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 57",
        id: "57",
        image: "/canvases/57_VEE22.jpg",
        start: "645",
        width: "2",
        height: "4"
      },      
      {
        name: "Canvas 58",
        id: "58",
        image: "/canvases/58_VEE22.jpg",
        start: "653",
        width: "2",
        height: "4"
      }
    ]
  },
  {
    name: "NYC22",
    id: "nyc22",
    description: "A paint party for NYC 22",
    canvases: [
      {
        name: "Canvas 59",
        id: "59",
        image: "/canvases/59_NYC22.jpg",
        start: "661",
        width: "4",
        height: "10"
      },
      {
        name: "Canvas 60",
        id: "60",
        image: "/canvases/60_NYC22.jpg",
        start: "701",
        width: "4",
        height: "10"
      },      
      {
        name: "Canvas 61",
        id: "61",
        image: "/canvases/61_NYC22.jpg",
        start: "741",
        width: "4",
        height: "10"
      },
      {
        name: "Canvas 62",
        id: "62",
        image: "/canvases/62_NYC22.jpg",
        start: "781",
        width: "4",
        height: "10"
      },        
      {
        name: "Canvas 63",
        id: "63",
        image: "/canvases/63_NYC22.jpg",
        start: "821",
        width: "4",
        height: "10"
      },          
      {
        name: "Canvas 64",
        id: "64",
        image: "/canvases/64_NYC22.jpg",
        start: "861",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 65",
        id: "65",
        image: "/canvases/65_NYC22.jpg",
        start: "893",
        width: "4",
        height: "8"
      },           
      {
        name: "Canvas 66",
        id: "66",
        image: "/canvases/66_NYC22.jpg",
        start: "925",
        width: "4",
        height: "8"
      },           
      {
        name: "Canvas 67",
        id: "67",
        image: "/canvases/67_NYC22.jpg",
        start: "957",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 68",
        id: "68",
        image: "/canvases/68_NYC22.jpg",
        start: "989",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 69",
        id: "69",
        image: "/canvases/69_NYC22.jpg",
        start: "1021",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 70",
        id: "70",
        image: "/canvases/70_NYC22.jpg",
        start: "1053",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 71",
        id: "71",
        image: "/canvases/71_NYC22.jpg",
        start: "1085",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 72",
        id: "72",
        image: "/canvases/72_NYC22.jpg",
        start: "1117",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 73",
        id: "73",
        image: "/canvases/73_NYC22.jpg",
        start: "1149",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 74",
        id: "74",
        image: "/canvases/74_NYC22.jpg",
        start: "1181",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 75",
        id: "75",
        image: "/canvases/75_NYC22.jpg",
        start: "1213",
        width: "4",
        height: "8"
      }, 
      {
        name: "Canvas 76",
        id: "76",
        image: "/canvases/76_NYC22.jpg",
        start: "1245",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 77",
        id: "77",
        image: "/canvases/77_NYC22.jpg",
        start: "1277",
        width: "4",
        height: "8"
      },
      {
        name: "Canvas 78",
        id: "78",
        image: "/canvases/78_NYC22.jpg",
        start: "1309",
        width: "4",
        height: "8"
      },            
      {
        name: "Canvas 79",
        id: "79",
        image: "/canvases/79_NYC22.jpg",
        start: "1341",
        width: "4",
        height: "8"
      }                                                                                           
    ]
  }  
];

export function getCanvas({ canvasId, cityId }) {
  return cities
    .find(({ id }) => id === cityId)
    .resources.find(({ id }) => id === canvasId);
}

export function getCity(cityId) {
  return cities.find(({ id }) => id === cityId);
}

export function getCities() {
  return cities;
}

