// Styles
import './styles/App.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Cell } from 'react-foundation'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, Outlet } from "react-router-dom";

function App() {
  const [loaded, setLoaded] = useState(false);

  function changeBackground(e) {
    let city = e.target.getAttribute("data-city");
    e.target.style.backgroundImage = `url('/cities/` + city + `-2.png')`;
    e.target.classList.toggle("active");
  }  

  function changeBackgroundBack(e) {
    let city = e.target.getAttribute("data-city");
    e.target.style.backgroundImage = `url('/cities/` + city + `-1.png')`;
    e.target.classList.toggle("active");    
  } 

  useEffect(() => {
    if (!loaded){  
      document.body.classList.add('body-canvas');
      window.scrollTo(0, 0);
      setLoaded(true)
    }
  },[loaded]);

  const { cityId } = useParams();
  console.log(cityId, 'cityId')

  return (
    <HelmetProvider >
      <Helmet>
        <title>FewoWorld</title>
        <meta name="description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta name="theme-color" content="#e3c20d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FewoWorld" />
        <meta property="og:description" content="Welcome to FewoWorld, a universe created by FEWOCiOUS and the Web3 community." />
        <meta property="og:image" content={'fewoworld-og-image.jpg'} />        
      </Helmet> 
      <div className="standard-page" style={{backgroundImage: `url('/canvas-pg-bg-2.jpg')`}}>
        <section className="inline-section">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
                <Cell small={12} medium={6} large={6}>
                  <Link to={'/'} className="logo-link-wrapper"><img className="App-logo" src={'/fewoworld-logo-paper@2x.png'} /></Link>
                </Cell>
            </Grid>
          </div>          
        </section>
        <>          
          <Outlet/>
        </>
       </div>
    </HelmetProvider>

  );
}
export default App;